/**
 * Copyright 2024 Illumio, Inc. All Rights Reserved.
 */
import intl from '@illumio-shared/utils/intl';
import * as GridUtils from 'components/Grid/GridUtils';
import {formatButtons, formatArrow} from './LabelRulesListUtils';
import LabelRulesListEditLabels from './Edit/Labels/LabelRulesListEditLabels';
import LabelRulesListViewExpression from './View/Expression/LabelRulesListViewExpression';
import LabelRulesListViewLabels from './View/Labels/LabelRulesListViewLabels';
import LabelRulesListEditExpression from './Edit/Expression/LabelRulesListEditExpression';
import styles from './LabelRulesList.css';
import UserName from 'containers/UserName/UserName';
import LabelRulesListEditRuleNumber from './Edit/RuleNumber/LabelRulesListEditRuleNumber';
import {createSelector} from 'reselect';
import {isLabelingRulesWriteApplicable} from 'containers/User/UserState';

export const getGridSettings = createSelector([isLabelingRulesWriteApplicable], labelingRulesWriteApplicable => ({
  id: 'labelruleslist',
  capacities: [25, 50, 100, 250, 500],
  capacity: 25,
  maxPage: Number.MAX_SAFE_INTEGER,
  showColumns: false,
  showCapacity: true,
  showPagination: true,
  columns: {
    checkboxes: {
      disabled: !labelingRulesWriteApplicable,
    },
    ruleNumber: {
      header: intl('Rulesets.Rules.IndexColumnLabel'),
      sortable: false,
      format: ({row, component}) => {
        const ruleNumber = row.data.ruleEditorState?.ruleNumber;
        const maxRuleNumber = component.maxRuleNumber;
        const handleRuleNumberChange = component.onRuleNumberChange;

        return (
          <div className={styles.ruleNumberWrapper}>
            {row.data.isInEditMode ? (
              <LabelRulesListEditRuleNumber
                ruleNumber={ruleNumber}
                maxRuleNumber={maxRuleNumber}
                onChange={handleRuleNumberChange}
              />
            ) : (
              row.data.rule.ruleNumber
            )}
          </div>
        );
      },
    },
    condition: {
      header: intl('LabelRules.Condition'),
      sortable: false,
      value: ({row}) => row.data.rule.expression,
      format: ({row, component}) => {
        const handleOnExpressionChange = component.onExpressionChange;

        return row.data.isInEditMode ? (
          <LabelRulesListEditExpression
            expression={row.data.ruleEditorState?.expression}
            onChange={handleOnExpressionChange}
          />
        ) : (
          <LabelRulesListViewExpression expression={row.data.rule.expression} />
        );
      },
    },
    arrow: {format: formatArrow},
    assignedLabel: {
      header: intl('Common.Labels'),
      sortable: false,
      format: ({row, component}) => {
        const handleOnLabelsChange = component.onLabelsChange;

        return row.data.isInEditMode ? (
          <LabelRulesListEditLabels labels={row.data.ruleEditorState?.labels} onChange={handleOnLabelsChange} />
        ) : (
          <LabelRulesListViewLabels labels={row.data.rule?.labels} />
        );
      },
    },
    lastModified: {
      header: intl('Common.LastModified'),
      sortable: false,
      isDate: true,
      value: ({row}) => row.data.rule.updatedAt || row.data.rule.createdAt || null,
    },
    modifiedBy: GridUtils.clickableColumn({
      header: intl('Common.ModifiedBy'),
      sortable: false,
      value: ({row}) => row.data.rule.updatedBy?.full_name,
      format: ({row, clickableRef}) =>
        row.data.rule.updatedBy && (
          <UserName
            linkProps={{theme: styles, themePrefix: 'modifiedBy-', themeCompose: 'merge'}}
            user={row.data.rule.updatedBy}
            display="full_name"
            ref={clickableRef}
          />
        ),
    }),
    buttons: {
      sortable: false,
      format: formatButtons,
      disabled: !labelingRulesWriteApplicable,
    },
  },

  templates: [
    [
      // @ 1153px and above, we limit the width of modifiedBy to save space for
      // condition and assignedLabel.
      {columns: ['checkboxes'], size: 'min-content'},
      {columns: ['ruleNumber'], size: 'max-content'},
      {columns: ['condition'], size: 'auto'},
      {columns: ['arrow'], size: 'min-content'},
      {columns: ['assignedLabel'], size: 'auto'},
      {columns: ['lastModified'], size: 'minmax(calc(1.24 * var(--100px)), auto)'},
      {columns: ['modifiedBy'], size: 'minmax(calc(1.12 * var(--100px)), calc(1.8 * var(--100px)))'},
      {columns: ['buttons'], size: 'min-content'},
    ],
    {
      maxWidth: 1152,
      template() {
        // @ 1152px and below, we reduce the width of lastModified & modifiedBy,
        // which wraps the text. This saves space for condition and assignedLabel.
        return [
          {columns: ['checkboxes'], size: 'min-content'},
          {columns: ['ruleNumber'], size: 'max-content'},
          {columns: ['condition'], size: 'auto'},
          {columns: ['arrow'], size: 'min-content'},
          {columns: ['assignedLabel'], size: 'auto'},
          {columns: ['lastModified'], size: 'calc(1.24 * var(--100px))'},
          {columns: ['modifiedBy'], size: 'minmax(calc(1.12 * var(--100px)), calc(1.25 * var(--100px)))'},
          {columns: ['buttons'], size: 'min-content'},
        ];
      },
    },
    {
      maxWidth: 960,
      template() {
        // @ 960px and below, we stack lastModified & modifiedBy to save space
        // for condition and assignedLabel.
        return [
          {columns: ['checkboxes'], size: 'min-content'},
          {columns: ['ruleNumber'], size: 'max-content'},
          {columns: ['condition'], size: 'auto'},
          {columns: ['arrow'], size: 'min-content'},
          {columns: ['assignedLabel'], size: 'auto'},
          {columns: ['lastModified', 'modifiedBy'], size: 'calc(1.24 * var(--100px))'},
          {columns: ['buttons'], size: 'min-content'},
        ];
      },
    },
    {
      maxWidth: 800,
      template() {
        // @ 800px and below, we stack checkboxes & ruleNumber, and lastModified
        // & modifiedBy to save space for condition and assignedLabel.
        return [
          {columns: ['checkboxes', 'ruleNumber'], size: 'max-content'},
          {columns: ['condition'], size: 'auto'},
          {columns: ['arrow'], size: 'min-content'},
          {columns: ['assignedLabel'], size: 'auto'},
          {columns: ['lastModified', 'modifiedBy'], size: 'calc(1.24 * var(--100px))'},
          {columns: ['buttons'], size: 'min-content'},
        ];
      },
    },
  ],
}));
