/**
 * Copyright 2024 Illumio, Inc. All Rights Reserved.
 */
import {createSelector} from 'reselect';
import {getGridSettings} from './LabelRulesListConfig';
import {getGridSelector} from 'components/Grid/GridSelectors';
import {getLabelsHrefMap} from '../../List/LabelListState';
import {getAllUsersMap} from 'containers/User/UserState';
import {createUIStateReducer} from '../LabelRulesStateUtils';
import {fillUserInfo} from 'containers/RBAC/RBACUtils';
import {NEW_LABEL_RULE_HREF, NEW_LABEL_RULE_EDITOR_STATE, ruleChanged} from './LabelRulesListUtils';

export default {
  list(state = [], action) {
    switch (action.type) {
      case 'LABEL_RULES_GET_LIST_SUCCESS':
        return action.data.list;
      default:
        return state;
    }
  },
  count(state = {}, action) {
    switch (action.type) {
      case 'LABEL_RULES_GET_LIST_SUCCESS':
        return action.data.count;
      default:
        return state;
    }
  },
  selected(state = [], action) {
    switch (action.type) {
      case 'LABEL_RULES_SELECT_ROWS':
        return [...state, ...action.rules];
      case 'LABEL_RULES_UNSELECT_ROWS':
        const unselecting = new Set(action.rules.map(({href}) => href));

        return state.filter(rule => !unselecting.has(rule.href));
      case 'LABEL_RULES_DELETE_SUCCESS':
        // clear selected links after delete call succeeds
        return [];
      default:
        return state;
    }
  },
  applyRulesPanelOpen(state = false, action) {
    switch (action.type) {
      case 'APPLY_RULES_PANEL_OPEN':
        return true;
      case 'APPLY_RULES_PANEL_CLOSE':
        return false;
      default:
        return state;
    }
  },
  ruleEditorState(state = null, action) {
    switch (action.type) {
      case 'LABEL_RULES_EDITOR_STATE_NEW_RULE':
        return {...NEW_LABEL_RULE_EDITOR_STATE, ruleNumber: action.ruleNumber};
      case 'LABEL_RULES_EDITOR_STATE_EDIT_RULE':
        return {...action.rule};
      case 'LABEL_RULES_EDITOR_STATE_SET_LABELS':
        return {...state, labels: [...action.labels]};
      case 'LABEL_RULES_EDITOR_STATE_SET_EXPRESSION':
        return {...state, expression: {...action.expression}};
      case 'LABEL_RULES_EDITOR_STATE_SET_RULE_NUMBER':
        return {...state, ruleNumber: action.ruleNumber};
      case 'LABEL_RULES_EDITOR_STATE_CLEAR':
        return null;
      default:
        return state;
    }
  },

  saveRuleUIState: createUIStateReducer({
    initActions: ['LABEL_RULES_SAVE_INIT'],
    successActions: ['LABEL_RULES_SAVE_SUCCESS'],
    failureActions: ['LABEL_RULES_SAVE_FAILED'],
  }),

  removeRulesUIState: createUIStateReducer({
    initActions: ['LABEL_RULES_DELETE_INIT'],
    successActions: ['LABEL_RULES_DELETE_SUCCESS'],
    failureActions: ['LABEL_RULES_DELETE_FAILED'],
  }),
};

export const getLabelRulesList = state => state.label.rules.list;
export const getLabelRulesCount = state => state.label.rules.count;
export const getSelectedLabelRules = state => state.label.rules.selected;
export const getRuleEditorState = state => state.label.rules.ruleEditorState;
export const getApplyRulesPanelOpen = state => state.label.rules.applyRulesPanelOpen;

export const getSaveRuleUIState = state => state.label.rules.saveRuleUIState;
export const getRemoveRulesUIState = state => state.label.rules.removeRulesUIState;

export const getLabelRules = createSelector(
  [getLabelRulesList, getLabelsHrefMap, getAllUsersMap],
  (rules, labelsObject, usersMap) => {
    // make labelsObject index-able by both href and "{key}--{value}"
    labelsObject = Object.values(labelsObject).reduce((labelsObject, label) => {
      labelsObject[`${label.key}--${label.value}`] = label;

      return labelsObject;
    }, labelsObject);

    return rules.map((rule, index) => {
      // find label objects for the rule based on either "href" or "{key}--{value}"
      const labels = rule.labels.reduce((labels, label) => {
        if (label.href && labelsObject[label.href]) {
          labels.push(labelsObject[label.href]);
        }

        if (label.key && label.value && labelsObject[`${label.key}--${label.value}`]) {
          labels.push(labelsObject[`${label.key}--${label.value}`]);
        }

        return labels;
      }, []);

      return {
        ...rule,
        ruleNumber: index + 1,
        labels,
        updatedBy: fillUserInfo(usersMap, rule.updatedBy),
      };
    });
  },
);

export const getGridRows = createSelector(
  [getLabelRules, getRuleEditorState, getSaveRuleUIState],
  (rules, ruleEditorState, saveRuleUIState) => {
    const {inProgress: saveInProgress = false, hasError: saveError = false} = saveRuleUIState;

    return [
      // if rule editor state is a new rule, insert it at the top
      ...(ruleEditorState?.href === NEW_LABEL_RULE_HREF
        ? [{ruleNumber: rules.length + 1, ...NEW_LABEL_RULE_EDITOR_STATE}]
        : []),
      ...rules,
    ].map(rule => {
      const isInEditMode = rule.href === ruleEditorState?.href;
      const isNewRule = rule.href === NEW_LABEL_RULE_HREF;
      const hasUnsavedData = isInEditMode && ruleChanged({rule, ruleEditorState});

      return {
        key: rule.href,
        selectable: !isNewRule,
        data: {
          isInEditMode,
          isNewRule,
          saveInProgress,
          saveError,
          ruleEditorState,
          hasUnsavedData,
          rule,
        },
      };
    });
  },
);

export const getLabelRulesListGrid = createSelector([state => state], state =>
  getGridSelector(state, {
    settings: getGridSettings,
    rows: getGridRows,
  }),
);

/**
 * returns true if one of the grid rows is in edit mode and has unsaved data;
 * returns false otherwise;
 * @type {Selector<boolean>}
 */
export const getHasUnsavedData = createSelector([getGridRows], rows => {
  return rows.some(row => row.data.hasUnsavedData);
});
