/**
 * Copyright 2020 Illumio, Inc. All Rights Reserved.
 */
import intl from '@illumio-shared/utils/intl';
import * as GridUtils from 'components/Grid/GridUtils';
import styles from './WorkloadVulnerabilities.css';
import {
  formatProProtocol,
  formatTraffic,
  formatVulnerability,
  formatPortExposure,
} from './WorkloadVulnerabilitiesUtils';
import {
  formatCVEIDs,
  formatVulnerabilityScore,
} from 'containers/IlluminationMap/Panels/Vulnerabilities/MapPanelVulnerabilitiesUtils';
import {createSelector} from 'reselect';
import {sortVulnerabilityPortExposure, sortVulnerabilities} from 'components/Vulnerability/VulnerabilityUtils';

export const gridSettings = createSelector(
  [options => options],
  ({showFullEnforcement, totalVEScore, vulnerabilitiesAreEnabled, isWorkloadManager}) => {
    return {
      id: 'workloadvulnerability',
      sort: '-currentVEScore',
      capacities: [25, 50, 100, 250, 500],
      capacity: 50,
      maxPage: Number.MAX_SAFE_INTEGER,
      showColumns: true,
      showCapacity: true,
      showPagination: true,
      columns: {
        currentVEScore: {
          header:
            totalVEScore.current === null
              ? intl('Vulnerability.CurrentVEScore')
              : intl(
                  'Vulnerability.CurrentVEScoreWithTotal',
                  {total: totalVEScore.current, className: styles.totalVEScore},
                  {html: true},
                ),
          value: ({row}) => row.currentVulnerabilityExposureScore,
          format: ({row, value}) => formatVulnerability(row, value),
          sort: ({row}) => ({...row, vulnerabilityExposureScore: row.currentVulnerabilityExposureScore}),
          sortFunction: sortVulnerabilities,
          disabled: !vulnerabilitiesAreEnabled,
        },
        potentialVEScore: {
          header: showFullEnforcement
            ? totalVEScore.full === null
              ? intl('Vulnerability.FullEnforcementVEScore')
              : intl(
                  'Vulnerability.FullEnforcementVEScoreWithTotal',
                  {total: totalVEScore.full, className: styles.totalVEScore},
                  {html: true},
                )
            : totalVEScore.visibility_only === null
            ? intl('Vulnerability.VisibilityOnlyVEScore')
            : intl(
                'Vulnerability.VisibilityOnlyVEScoreWithTotal',
                {total: totalVEScore.visibility_only, className: styles.totalVEScore},
                {html: true},
              ),
          value: ({row}) =>
            showFullEnforcement ? row.fullVulnerabilityExposureScore : row.visibilityVulnerabilityExposureScore,
          format: ({row, value}) => formatVulnerability(row, value),
          sort: ({row}) => {
            const mode = showFullEnforcement
              ? 'fullVulnerabilityExposureScore'
              : 'visibilityVulnerabilityExposureScore';
            const vulnerabilityExposureScore = row[mode];

            return {...row, vulnerabilityExposureScore};
          },
          sortFunction: sortVulnerabilities,
          disabled: !vulnerabilitiesAreEnabled,
        },
        currentExposure: {
          header: intl('Vulnerability.CurrentExposure'),
          value: ({row}) => formatPortExposure(row, row.currentVulnerablePortExposure),
          sort: ({row}) => ({...row, portExposure: row.currentVulnerablePortExposure}),
          sortFunction: sortVulnerabilityPortExposure,
        },
        potentialExposure: {
          header: showFullEnforcement
            ? intl('Vulnerability.FullEnforcementExposure')
            : intl('Vulnerability.VisibilityOnlyExposure'),
          value: ({row}) =>
            formatPortExposure(
              row,
              showFullEnforcement ? row.fullVulnerablePortExposure : row.visibilityVulnerablePortExposure,
            ),
          sort: ({row}) => {
            const mode = showFullEnforcement ? 'fullVulnerablePortExposure' : 'visibilityVulnerablePortExposure';
            const portExposure = row[mode];

            return {...row, portExposure};
          },
          sortFunction: sortVulnerabilityPortExposure,
        },
        traffic: {
          header: intl('Vulnerability.ProvidedTraffic'),
          value: ({row}) => row.traffic,
          format: ({value}) => formatTraffic(value),
          disabled: isWorkloadManager,
        },
        port: {
          header: intl('Port.Protocol'),
          value: ({row}) => formatProProtocol(row),
        },
        protocol: {
          header: intl('Vulnerability.CVEIds'),
          value: ({row}) => formatCVEIDs(row),
        },
        name: {
          header: intl('Common.Name'),
          value: ({row}) => row.details.name,
        },
        vulnerabilityStandardScore: {
          header: intl('Vulnerability.VulnerabilityStandardScore'),
          value: ({row}) => row.severity,
          format: ({value}) => formatVulnerabilityScore(value),
        },
      },

      templates: [
        [
          {columns: ['potentialVEScore'], size: 'minmax(100px, auto)'},
          {columns: ['currentVEScore'], size: 'minmax(100px, auto)'},
          {columns: ['potentialExposure'], size: 'minmax(100px, auto)'},
          {columns: ['currentExposure'], size: 'minmax(100px, auto)'},
          {columns: ['traffic'], size: 'minmax(100px, auto)'},
          {columns: ['port'], size: 'minmax(100px, auto)'},
          {columns: ['protocol'], size: 'minmax(100px, auto)'},
          {columns: ['name'], size: 'minmax(100px, auto)'},
          {columns: ['vulnerabilityStandardScore'], size: 'minmax(100px, auto)'},
        ],
        {
          maxWidth: 800,
          template(columns) {
            if (GridUtils.hasOptionalColumns(columns)) {
              //all column breakpoint
              return [
                {columns: ['potentialVEScore'], size: 'minmax(100px, auto)'},
                {columns: ['currentVEScore'], size: 'minmax(100px, auto)'},
                {columns: ['potentialExposure'], size: 'minmax(100px, auto)'},
                {columns: ['currentExposure'], size: 'minmax(100px, auto)'},
                {columns: ['traffic'], size: 'minmax(100px, auto)'},
                {columns: ['port'], size: 'minmax(100px, auto)'},
                {columns: ['protocol'], size: 'minmax(100px, auto)'},
                {columns: ['name'], size: 'minmax(100px, auto)'},
                {columns: ['vulnerabilityStandardScore'], size: 'minmax(100px, auto)'},
              ];
            }

            return [
              {columns: ['potentialVEScore'], size: 'minmax(100px, auto)'},
              {columns: ['currentVEScore'], size: 'minmax(100px, auto)'},
              {columns: ['potentialExposure'], size: 'minmax(100px, auto)'},
              {columns: ['currentExposure'], size: 'minmax(100px, auto)'},
              {columns: ['traffic'], size: 'minmax(100px, auto)'},
              {columns: ['port'], size: 'minmax(100px, auto)'},
              {columns: ['protocol'], size: 'minmax(100px, auto)'},
              {columns: ['name'], size: 'minmax(100px, auto)'},
              {columns: ['vulnerabilityStandardScore'], size: 'minmax(100px, auto)'},
            ];
          },
        },
        {
          maxWidth: 640,
          template(columns) {
            if (GridUtils.hasOptionalColumns(columns)) {
              //all column breakpoint
              return [
                {
                  columns: ['potentialVEScore', 'currentVEScore', 'potentialExposure', 'currentExposure'],
                  size: 'minmax(200px, auto)',
                },
                {
                  columns: ['traffic', 'port', 'protocol', 'name', 'vulnerabilityStandardScore'],
                  size: 'minmax(200px, auto)',
                },
              ];
            }

            return [
              {
                columns: ['potentialVEScore', 'currentVEScore', 'potentialExposure', 'currentExposure'],
                size: 'minmax(200px, auto)',
              },
              {
                columns: ['traffic', 'port', 'protocol', 'name', 'vulnerabilityStandardScore'],
                size: 'minmax(200px, auto)',
              },
            ];
          },
        },
      ],
    };
  },
);
