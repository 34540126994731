/**
 * Copyright 2023 Illumio, Inc. All Rights Reserved.
 */

import intl from '@illumio-shared/utils/intl';
import {
  formatPortExposure,
  formatProProtocol,
  formatVulnerability,
} from 'containers/Workload/Item/Vulnerabilities/WorkloadVulnerabilitiesUtils';
import {formatCVEIDs, formatVulnerabilityScore} from './MapPanelVulnerabilitiesUtils';
import {sortVulnerabilities, sortVulnerabilityPortExposure} from 'components/Vulnerability/VulnerabilityUtils';

export const getVulnerabilitiesGridConfig = ({gridId, isFullEnforcement}) => {
  if (gridId === 'vulnerabilitieslistworkload') {
    return {
      id: 'vulnerabilitieslistworkload',
      sort: '-currentVEScore',
      capacities: [25, 50, 100, 250, 500],
      capacity: 50,
      maxPage: Number.MAX_SAFE_INTEGER,
      showColumns: true,
      showCapacity: true,
      showPagination: true,
      columns: {
        potentialVEScore: {
          header: isFullEnforcement
            ? intl('Vulnerability.FullEnforcementVEScore')
            : intl('Vulnerability.VisibilityOnlyVEScore'),
          value: ({row}) =>
            isFullEnforcement ? row.fullVulnerabilityExposureScore : row.visibilityVulnerabilityExposureScore,
          format: ({row, value}) => formatVulnerability(row, value),
          sort: ({row}) => {
            const mode = isFullEnforcement ? 'fullVulnerabilityExposureScore' : 'visibilityVulnerabilityExposureScore';
            const vulnerabilityExposureScore = row[mode];

            return {...row, vulnerabilityExposureScore};
          },
          sortFunction: sortVulnerabilities,
        },
        currentVEScore: {
          header: intl('Vulnerability.CurrentVEScore'),
          value: ({row}) => row.currentVulnerabilityExposureScore,
          format: ({row, value}) => formatVulnerability(row, value),
          sort: ({row}) => ({...row, vulnerabilityExposureScore: row.currentVulnerabilityExposureScore}),
          sortFunction: sortVulnerabilities,
        },
        potentialExposure: {
          header: isFullEnforcement
            ? intl('Vulnerability.FullEnforcementExposure')
            : intl('Vulnerability.VisibilityOnlyExposure'),
          value: ({row}) =>
            formatPortExposure(
              row,
              isFullEnforcement ? row.fullVulnerablePortExposure : row.visibilityVulnerablePortExposure,
            ),
          sort: ({row}) => {
            const mode = isFullEnforcement ? 'fullVulnerablePortExposure' : 'visibilityVulnerablePortExposure';
            const portExposure = row[mode];

            return {...row, portExposure};
          },
          sortFunction: sortVulnerabilityPortExposure,
        },
        currentExposure: {
          header: intl('Vulnerability.CurrentExposure'),
          value: ({row}) => formatPortExposure(row, row.currentVulnerablePortExposure),
          sort: ({row}) => ({...row, portExposure: row.currentVulnerablePortExposure}),
          sortFunction: sortVulnerabilityPortExposure,
        },
        port: {
          header: intl('Port.Protocol'),
          value: ({row}) => formatProProtocol(row),
        },
        cveIds: {
          header: intl('Vulnerability.CVEIds'),
          value: ({row}) => formatCVEIDs(row),
        },
        name: {
          header: intl('Common.Name'),
          value: ({row}) => row.details.name,
        },
        vulnerabilityStandardScore: {
          header: intl('Vulnerability.VulnerabilityStandardScore'),
          value: ({row}) => row.severity,
          format: ({value}) => formatVulnerabilityScore(value),
        },
      },

      templates: [
        [
          {columns: ['potentialVEScore'], size: 'minmax(60px, auto)'},
          {columns: ['currentVEScore'], size: 'minmax(60px, auto)'},
          {columns: ['potentialExposure'], size: 'minmax(80px, auto)'},
          {columns: ['currentExposure'], size: 'minmax(80px, auto)'},
          {columns: ['port'], size: 'minmax(100px, auto)'},
          {columns: ['cveIds'], size: 'minmax(100px, auto)'},
          {columns: ['name'], size: 'minmax(100px, auto)'},
          {columns: ['vulnerabilityStandardScore'], size: 'minmax(90px, auto)'},
        ],
        {
          maxWidth: 700,
          template() {
            return [
              {columns: ['potentialVEScore'], size: 'minmax(60px, auto)'},
              {columns: ['currentVEScore'], size: 'minmax(60px, auto)'},
              {columns: ['potentialExposure'], size: 'minmax(80px, auto)'},
              {columns: ['currentExposure'], size: 'minmax(80px, auto)'},
              {columns: ['port'], size: 'minmax(100px, auto)'},
              {columns: ['cveIds', 'name'], size: 'minmax(100px, auto)'},
              {columns: ['vulnerabilityStandardScore'], size: 'minmax(90px, auto)'},
            ];
          },
        },
        {
          maxWidth: 600,
          template() {
            return [
              {
                columns: ['potentialVEScore', 'currentVEScore', 'potentialExposure', 'currentExposure'],
                size: 'minmax(200px, auto)',
              },
              {columns: ['port', 'cveIds', 'name', 'vulnerabilityStandardScore'], size: 'minmax(200px, auto)'},
            ];
          },
        },
      ],
    };
  }

  return {
    id: 'vulnerabilitieslist',
    sort: '-currentVEScore',
    capacities: [25, 50, 100, 250, 500],
    capacity: 50,
    maxPage: Number.MAX_SAFE_INTEGER,
    showColumns: true,
    showCapacity: true,
    showPagination: true,
    columns: {
      potentialVEScore: {
        header: isFullEnforcement
          ? intl('Vulnerability.FullEnforcementVEScore')
          : intl('Vulnerability.VisibilityOnlyVEScore'),
        value: ({row}) =>
          isFullEnforcement ? row.fullVulnerabilityExposureScore : row.visibilityVulnerabilityExposureScore,
        format: ({row, value}) => formatVulnerability(row, value),
        sort: ({row}) => {
          const mode = isFullEnforcement ? 'fullVulnerabilityExposureScore' : 'visibilityVulnerabilityExposureScore';
          const vulnerabilityExposureScore = row[mode];

          return {...row, vulnerabilityExposureScore};
        },
        sortFunction: sortVulnerabilities,
      },
      currentVEScore: {
        header: intl('Vulnerability.CurrentVEScore'),
        value: ({row}) => row.currentVulnerabilityExposureScore,
        format: ({row, value}) => formatVulnerability(row, value),
        sort: ({row}) => ({...row, vulnerabilityExposureScore: row.currentVulnerabilityExposureScore}),
        sortFunction: sortVulnerabilities,
      },
      potentialExposure: {
        header: isFullEnforcement
          ? intl('Vulnerability.FullEnforcementExposure')
          : intl('Vulnerability.VisibilityOnlyExposure'),
        value: ({row}) =>
          formatPortExposure(
            row,
            isFullEnforcement ? row.fullVulnerablePortExposure : row.visibilityVulnerablePortExposure,
          ),
        sort: ({row}) => {
          const mode = isFullEnforcement ? 'fullVulnerablePortExposure' : 'visibilityVulnerablePortExposure';
          const portExposure = row[mode];

          return {...row, portExposure};
        },
        sortFunction: sortVulnerabilityPortExposure,
      },
      currentExposure: {
        header: intl('Vulnerability.CurrentExposure'),
        value: ({row}) => formatPortExposure(row, row.currentVulnerablePortExposure),
        sort: ({row}) => ({...row, portExposure: row.currentVulnerablePortExposure}),
        sortFunction: sortVulnerabilityPortExposure,
      },
      workloads: {
        header: intl('Common.Workloads'),
        value: ({row}) => row.numWorkloads,
      },
      port: {
        header: intl('Port.Protocol'),
        value: ({row}) => formatProProtocol(row),
      },
      cveIds: {
        header: intl('Vulnerability.CVEIds'),
        value: ({row}) => formatCVEIDs(row),
      },
      name: {
        header: intl('Common.Name'),
        value: ({row}) => row.details.name,
      },
      vulnerabilityStandardScore: {
        header: intl('Vulnerability.VulnerabilityStandardScore'),
        value: ({row}) => row.severity,
        format: ({value}) => formatVulnerabilityScore(value),
      },
    },

    templates: [
      [
        {columns: ['potentialVEScore'], size: 'minmax(60px, auto)'},
        {columns: ['currentVEScore'], size: 'minmax(60px, auto)'},
        {columns: ['potentialExposure'], size: 'minmax(80px, auto)'},
        {columns: ['currentExposure'], size: 'minmax(80px, auto)'},
        {columns: ['workloads'], size: 'minmax(80px, auto)'},
        {columns: ['port'], size: 'minmax(100px, auto)'},
        {columns: ['cveIds'], size: 'minmax(100px, auto)'},
        {columns: ['name'], size: 'minmax(100px, auto)'},
        {columns: ['vulnerabilityStandardScore'], size: 'minmax(90px, auto)'},
      ],
      {
        maxWidth: 700,
        template() {
          return [
            {columns: ['potentialVEScore'], size: 'minmax(60px, auto)'},
            {columns: ['currentVEScore'], size: 'minmax(60px, auto)'},
            {columns: ['potentialExposure'], size: 'minmax(80px, auto)'},
            {columns: ['currentExposure'], size: 'minmax(80px, auto)'},
            {columns: ['workloads'], size: 'minmax(80px, auto)'},
            {columns: ['port'], size: 'minmax(100px, auto)'},
            {columns: ['cveIds', 'name'], size: 'minmax(100px, auto)'},
            {columns: ['vulnerabilityStandardScore'], size: 'minmax(90px, auto)'},
          ];
        },
      },
      {
        maxWidth: 600,
        template() {
          return [
            {
              columns: ['potentialVEScore', 'currentVEScore', 'potentialExposure', 'currentExposure'],
              size: 'minmax(200px, auto)',
            },
            {
              columns: ['workloads', 'port', 'cveIds', 'name', 'vulnerabilityStandardScore'],
              size: 'minmax(200px, auto)',
            },
          ];
        },
      },
    ],
  };
};
