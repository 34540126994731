/**
 * Copyright 2021 Illumio, Inc. All Rights Reserved.
 */
import _ from 'lodash';
import intl from '@illumio-shared/utils/intl';
import {createContext, useCallback, useContext, useEffect, useLayoutEffect, useMemo, useRef, useState} from 'react';
import {useSelector} from '@illumio-shared/utils/redux';
import {sticky} from 'tippy.js';
import {composeThemeFromProps} from '@css-modules-theme/react';
import PropTypes from 'prop-types';
import maxSize from 'popper-max-size-modifier';
import {KEY_BACK_SPACE, KEY_DOWN, KEY_ESCAPE, KEY_LEFT, KEY_RIGHT, KEY_TAB, KEY_UP} from 'keycode-js';
import {Button, StatusIcon, Tooltip, TypedMessages} from 'components';
import {fetchValidRecents, updateSelectorHistory} from './SelectorSaga';
import {getValidSelectorRecents} from './SelectorState';
import {AppContext} from 'containers/App/AppUtils';
import {ModalContext} from 'components/Modal/ModalUtils';
import {domUtils, reactUtils, tidUtils} from '@illumio-shared/utils';
import {generalUtils} from '@illumio-shared/utils/shared';

import {
  calculateConflicts,
  categorySuggestionRegex,
  COLUMN_WIDTH,
  combinedCategoryId,
  DROPDOWN_ID,
  getAllResourcesObject,
  getLastSelectedActiveCategory,
  getNextVisibleCategoryId,
  getOptionId,
  INPUT_ID,
  INPUT_ID_EXPANDABLE,
  isCategorySelectable,
  isHighlightedBlockEvent,
  isMovingHighlighted,
  isValuesMapEqual,
  pickSuggestion,
  populateCombinedCategory,
  populateSearchPromises,
  resolveSelectIntoResource,
  sanitizeOption,
  SEARCHBAR_CONTAINER_ID,
  shouldCloseDropdown,
  VALUEPANEL_ID,
  normalizeValues,
} from './SelectorUtils';
import {useFilialPiety} from './SelectorFormatUtils';
import SearchBar from './SearchBar';
import Dropdown from './Dropdown';
import MultiModeSelector from './MultiModeSelector';
import GridFilter from './GridFilter/GridFilter';
import styles from './Selector.css';
import {produce, enableMapSet} from 'immer';

import {CategoryPresets} from './Presets';
import cx from 'classnames';

enableMapSet(); //To enable Immer to operate on the native Map and Set collections

Selector.Context = createContext(null);

Selector.propTypes = {
  tid: PropTypes.string, // Additional tid that will be added to default one
  values: PropTypes.instanceOf(Map), // items pre-selected {[resourceId1]: values1: array, [resourceId2]: values2: array}
  alwaysTakeValuesFromProps: PropTypes.bool, // If true, values will be taken from props even if they are already selected in the selector

  autoFocus: PropTypes.bool,
  notExpandable: PropTypes.bool,
  // Whether selector should have border and error message
  errors: PropTypes.object,
  errorMessage: PropTypes.string,
  hideErrorMessage: PropTypes.bool,

  helpInfo: PropTypes.any,
  enableReset: PropTypes.bool,

  insensitive: PropTypes.bool, // Makes selector not interactable (not clickable, not tabbable), useful when API call is in progress
  disabled: PropTypes.bool, // Makes selector Input insensitive and applies disabled style
  hideClearAll: PropTypes.bool, // hide 'x' button on the right to delete all items at once
  title: PropTypes.oneOfType([PropTypes.string, PropTypes.func]), // title shown above the searchbar (note: disables active category indicator)
  label: PropTypes.oneOfType([PropTypes.string, PropTypes.element]),
  placeholder: PropTypes.string, // placeholder text to show in selector input bar
  noActiveIndicator: PropTypes.bool,
  inputProps: PropTypes.object, // Props to be passed to selector Input box, e.g. autoFocus: true, data-tid etc

  closeDropdownOnSelection: PropTypes.bool, // Close dropdown on selection change
  dropdownTippyProps: PropTypes.object, // Tooltip props for dropdown tippy
  hideCategoryPanel: PropTypes.bool, // Pass true to hide categoryPanel. for e.g. in basic view in rule editor
  dropdownMaxHeight: PropTypes.number, // maxHeight of dropdown
  dropdownMinHeight: PropTypes.number, // minHeight of dropdown
  searchBarMaxHeight: PropTypes.number, // maxHeight of search bar
  maxColumns: PropTypes.oneOfType([PropTypes.number, PropTypes.oneOf(['auto'])]), // determines maxWidth, 1 col = 180 px

  activeCategoryId: PropTypes.oneOfType([PropTypes.string, PropTypes.func]), // active category ID

  footer: PropTypes.any, // custom footer to put at the bottom of the selector
  footerProps: PropTypes.object, // custom footer to put at the bottom of the selector
  noFooter: PropTypes.bool, // removes the default footer
  onSelectionChange: PropTypes.func,
  onOpen: PropTypes.func,
  onClose: PropTypes.func,
  hideOptions: PropTypes.bool,

  stickyCategoryPosition: PropTypes.oneOf(['top', 'bottom']),
  showCombinedCategory: PropTypes.bool,
  categories: PropTypes.arrayOf(
    PropTypes.oneOfType([
      PropTypes.shape({divider: PropTypes.bool}),
      PropTypes.shape({
        id: PropTypes.string.isRequired,
        name: PropTypes.string,
        placeholder: PropTypes.string, // placeholder text to show in selector input bar when this category is active

        template: PropTypes.string, // Grid area template for resources
        format: PropTypes.any, // Format category name, e.g. Adding icons/tooltip or custom style
        displayResourceAsCategory: PropTypes.bool,
        noActiveIndicator: PropTypes.bool,
        maxColumns: PropTypes.oneOfType([PropTypes.number, PropTypes.oneOf(['auto'])]), // determines maxWidth, 1 col = 180 px

        // type: 'list' | 'container'
        resources: PropTypes.objectOf(
          PropTypes.oneOfType([
            PropTypes.shape({
              type: PropTypes.string, //type: 'container'
              container: PropTypes.node,
              // If a container is a form then it must pass formProps with form id
              containerProps: PropTypes.oneOfType([PropTypes.object, PropTypes.func]),
              unsavedWarningData: PropTypes.object,
              selectIntoResource: PropTypes.oneOfType([PropTypes.string, PropTypes.func]),
              enableFocusLock: PropTypes.bool,

              conflict: PropTypes.func,
              conflictResolve: PropTypes.oneOfType([PropTypes.func, PropTypes.oneOf(['replace', 'block'])]),

              // Hooks to set values, categories/resources, and errors on selecting an option
              onSelect: PropTypes.func,
              onUnselect: PropTypes.func,
            }),
            PropTypes.shape({
              type: PropTypes.string, //type: 'list'

              name: PropTypes.string, // Resource name
              format: PropTypes.any, // Format resource name, e.g. Adding icons/tooltip or custom style
              infoPanel: PropTypes.any, // Information Panel, collapsible
              infoPanelPosition: PropTypes.oneOf(['top', 'bottom']),
              // dropdown values
              statics: PropTypes.oneOfType([
                PropTypes.array, // Static values passed from the page
                PropTypes.func, // A function that returns an array of options
              ]),
              // API endpoint Or saga whose response will be merged with values
              // The result of dataProvider should be `{matches, num_matches}`
              dataProvider: PropTypes.oneOfType([
                PropTypes.func, // A saga that returns an object of {matches: array, num_matches: number}
                PropTypes.string, // Name of the api method, in 'class.method' notation, like 'labels.facets'
              ]),
              apiArgs: PropTypes.oneOfType([PropTypes.object, PropTypes.func]), //Api Options such as params, query etc. that are passed down to api.js/fetcher.js
              includeSelectedResources: PropTypes.oneOfType([PropTypes.array, PropTypes.func]), //Ids of resources that will be included in API Query
              validate: PropTypes.func, // Useful to validate user query string, throw a validation error if query string is not valid
              conflict: PropTypes.func, // Define if an option is conflicting with a selected value
              conflictResolve: PropTypes.oneOfType([PropTypes.func, PropTypes.oneOf(['replace', 'block'])]), // How to resolve option conflict

              optionProps: PropTypes.shape({
                format: PropTypes.func, // Can be a function, a text, a node to format the options e.g. add icon in option
                filterOptions: PropTypes.func, // A callback function to filter options
                showCheckbox: PropTypes.bool, // Whether to show checkbox for multiple select options
                hidden: PropTypes.bool,
                disabled: PropTypes.bool,
                visibilityWhenSelected: PropTypes.oneOf(['hidden', 'disabled', 'insensitive']),
                allowMultipleSelection: PropTypes.bool, // allows more than one value to be selected
                showSelected: PropTypes.bool, // when false, does not show the selected items; true by default

                isPill: PropTypes.bool,
                pillProps: PropTypes.oneOfType([PropTypes.func, PropTypes.object]),
                ...reactUtils.mutuallyExclusiveTruePropsSpread('format', 'isPill'),

                // Tooltip props
                tooltipProps: PropTypes.shape({
                  appearWhen: PropTypes.string, // Show tooltip when appearWhen resource is selected
                  content: PropTypes.oneOfType([PropTypes.string, PropTypes.func]),
                  // Other tooltip props can be passed in addition to appearWhen and content
                }),
              }),

              noEmptyBanner: PropTypes.bool,
              emptyBannerContent: PropTypes.any,

              enableHistory: PropTypes.bool,
              historyKey: PropTypes.string, // historyKey || dataProvider || resource id

              allowPartial: PropTypes.bool, // adds query string at the top of the list in case of no exact match
              // func returns bool/array, the array options should include flag isCreate: true
              allowCreateOptions: PropTypes.oneOfType([PropTypes.array, PropTypes.bool, PropTypes.func]),
              createHint: PropTypes.string,
              // Pass a container resourceId that will be unhidden on create option click,
              // Or, pass a callback that will return container resource id
              onCreateEnter: PropTypes.oneOfType([PropTypes.func, PropTypes.string]),

              selectedProps: PropTypes.shape({
                hidden: PropTypes.bool,
                resourceJoiner: PropTypes.oneOf(['or', 'and']),
                valueJoiner: PropTypes.oneOf(['or', 'and']),
                formatResource: PropTypes.func,
                formatValueText: PropTypes.func, // A callback to format selected value, e.g. add icon when error Or tooltip when selected
                formatValue: PropTypes.func, // A callback to format selected value, e.g. add icon when error Or tooltip when selected
                hideResourceName: PropTypes.bool,

                isPill: PropTypes.bool,
                joinerIsPill: PropTypes.bool,
                pillPropsValue: PropTypes.oneOfType([PropTypes.func, PropTypes.object]), // Pass any pillProps here. e.g. icon, category, pinned etc.
                pillPropsResource: PropTypes.oneOfType([PropTypes.func, PropTypes.object]),
                ...reactUtils.mutuallyExclusiveTruePropsSpread('formatValueText', 'isPill'),
              }),

              showTitle: PropTypes.bool, // whether to show "Name - 5 of 8 results" at the top of a resource

              hidden: PropTypes.oneOfType([PropTypes.bool, PropTypes.func]), // Hides the resource. @type: boolean | (() => boolean)
              insensitive: PropTypes.bool, // Makes resource insensitive
              disabled: PropTypes.bool, // Makes resource insensitive and apply disabled styles

              // Hooks to set values, categories/resources, and errors on selecting an option
              onSelect: PropTypes.func,
              onUnselect: PropTypes.func,
            }),
          ]),
        ),
      }),
    ]),
  ).isRequired,
  theme: PropTypes.object,
};

export default function Selector(props) {
  const {
    showCombinedCategory: showCombinedCategoryProp,
    categories: categoriesProp,
    closeDropdownOnSelection,
    disabled,
    errorMessage,
    footer,
    footerProps: {onFooterCheckboxChange} = {},
    noFooter,
    hideClearAll,
    hideErrorMessage,
    infoPanel,
    insensitive,
    placeholder = intl('Common.FilterView'),
    noActiveIndicator,
    hideCategoryPanel,
    helpInfo,
    enableReset,
    dropdownTippyProps = {},
    inputProps,
    inputProps: {onBlur} = {},
    searchBarMaxHeight,
    tid,
    title,
    label,
    onSelectionChange,
    values: valuesProp,
    alwaysTakeValuesFromProps,
    useCmdKey,
    onClose,
    onOpen,
    errors: errorsProp,
    hideOptions,
    dropdownMinHeight,
    notExpandable,
  } = props;

  const recents = useSelector(getValidSelectorRecents);

  const {
    fetcher,
    store: {prefetcher},
  } = useContext(AppContext);

  const isExpandable = !notExpandable;

  const theme = composeThemeFromProps(styles, props);
  const [dropdownTippyInstance, setDropdownTippyInstance] = useState(null);
  const [categoriesState, setCategoriesState] = useState(categoriesProp);
  const [errorsState, setErrorsState] = useState(errorsProp ?? {});
  const [valuesState, setValuesState] = useState(valuesProp ?? new Map());
  const [historyState, setHistoryState] = useState(recents);
  const [active, setActive] = useState(Boolean(props.autoOpen));
  const [query, setQuery] = useState('');
  const [suggestion, setSuggestion] = useState('');
  const [closeDropdown, setCloseDropdown] = useState(false);
  const [categoryPanelOnRight, setCategoryPanelOnRight] = useState(isExpandable);
  const [skipShowAll, setSkipShowAll] = useState(false);
  const [footerCheckbox, setFooterCheckbox] = useState(props.footerProps?.footerCheckbox);
  const autoFocus = !prefetcher.scrollRestored && props.autoFocus;

  const {childrenPropsMap, saveChildRef, registerChildHandlers, setHighlightedChild, resetHighlightedChild} =
    useFilialPiety();

  const modalContext = useContext(ModalContext);
  const selectorRef = useRef(null);
  // keep prefetcher form dirty props on Selector mount and reset it to previous value on unmount
  const parentFormDirtyRef = useRef(null);
  const highlightedPathArrRef = useRef([]);
  const highlightedRectRef = useRef();
  const highlightedElementRef = useRef();
  const shouldDispatchUpdateKVPairsRef = useRef(false);
  const hasFocusLockRef = useRef(null);
  const dropdownCloseIsHandledByParentRef = useRef();
  const focusLockGroupNameRef = useRef();

  // passed to FocusLock group prop in search bar and containerResource
  focusLockGroupNameRef.current ||= generalUtils.randomString(5, true);

  const prevPropRecentsRef = useRef(recents);

  const recentsPropChanged = !_.isEqual(prevPropRecentsRef.current, recents);

  const history = recentsPropChanged ? recents : historyState;

  const prevActiveCategoryIdRef = useRef(null); // Needed to trigger kvpairs update when category changes

  const prevStateCategoriesRef = useRef(props.categories); // Needed to go to previous categories state on Go Back action in container forms

  const prevPropValuesRef = useRef(null); // Needed to reinitalize Selector state when initial values changes

  prevPropValuesRef.current ||= valuesProp;

  const prevPropCategoriesRef = useRef(null); // Needed to reinitalize Selector state when initial categories changes

  prevPropCategoriesRef.current ||= categoriesProp;

  const categoriesPropHasChanged = !_.isEqual(prevPropCategoriesRef.current, categoriesProp);

  const categories = reactUtils.useDeepCompareMemo(categoriesPropHasChanged ? categoriesProp : categoriesState);
  const hasSelectableCategories = categories.filter(isCategorySelectable).length > 1;
  const showCombinedCategory = showCombinedCategoryProp && hasSelectableCategories;
  const combinedCategory = useMemo(
    () => (showCombinedCategory ? populateCombinedCategory({categories, theme}) : null),
    [categories, theme, showCombinedCategory],
  );

  const renderedCategories = reactUtils.useDeepCompareMemo([
    // Add combined category as the first category in category panel and add a divider with rest of the categories
    ...(combinedCategory ? [combinedCategory, {divider: true}] : []),
    ...categories,
  ]);

  const allResources = useMemo(
    () => getAllResourcesObject([combinedCategory ?? [], ...categories]),
    [combinedCategory, categories],
  );

  const historyIsEnabled = Object.values(allResources).some(({enableHistory}) => enableHistory);

  const valuesPropHasChanged = useMemo(
    () => !isValuesMapEqual(prevPropValuesRef.current, valuesProp, allResources),
    [valuesProp, allResources],
  );

  // Take values from prop if values prop has changed and new prop is not same as prev state
  // For e.g. In list pages, onSelectionChange receives the updated state and pass it back to Selector.
  // Selector should not reinitialize in this case even though values next prop is different from prev prop
  const shouldTakeValuesFromProp = useMemo(
    () =>
      alwaysTakeValuesFromProps || (valuesPropHasChanged && !isValuesMapEqual(valuesProp, valuesState, allResources)),
    [alwaysTakeValuesFromProps, valuesPropHasChanged, valuesProp, valuesState, allResources],
  );

  if (__DEV__ && categoriesPropHasChanged) {
    console.info('Modifying categories by parent has reinitialized Selector state');
  }

  const prevPropErrorsRef = useRef(null); // Needed to reinitalize Selector state when initial categories changes
  const errorsPropHasChanged = useMemo(() => !_.isEqual(prevPropErrorsRef.current, errorsProp), [errorsProp]);

  // Read values from props on selector reinitialize, otherwise read from state
  const unnormalizedValues = reactUtils.useDeepCompareMemo(
    shouldTakeValuesFromProp ? valuesProp ?? new Map() : valuesState,
  );
  const values = useMemo(() => normalizeValues(unnormalizedValues), [unnormalizedValues]);
  const errors = reactUtils.useDeepCompareMemo(errorsPropHasChanged ? errorsProp ?? {} : errorsState);

  // active category is set to the category of last value in values
  const lastSelectedValueCategoryId = useMemo(
    () => getLastSelectedActiveCategory({values, categories, allResources}),
    [allResources, values, categories],
  );

  // Get a visible category that renders its options in option panel
  const nextVisibleCategoryId = useMemo(() => getNextVisibleCategoryId(categories), [categories]);

  const noCategoryPanel = useMemo(() => hideCategoryPanel || categories.length === 1, [categories, hideCategoryPanel]);

  //FIXME: find next visible category Id if active category becomes hidden
  const activeCategoryIdState = reactUtils.useDeepCompareMemo(
    categoriesState.find(({active}) => active)?.id ?? (showCombinedCategory ? combinedCategoryId : undefined),
  );
  const nextPropActiveCategoryId =
    typeof props.activeCategoryId === 'function'
      ? props.activeCategoryId(query, activeCategoryIdState)
      : props.activeCategoryId ?? (showCombinedCategory ? combinedCategoryId : undefined);
  const prevPropActiveCategoryId = useRef();

  const activeCategoryIdChangedByProp = nextPropActiveCategoryId !== prevPropActiveCategoryId.current;

  const activeCategoryId =
    (activeCategoryIdChangedByProp ? nextPropActiveCategoryId : activeCategoryIdState) ??
    lastSelectedValueCategoryId ??
    nextVisibleCategoryId;

  const activeCategory = reactUtils.useDeepCompareMemo(
    showCombinedCategory && activeCategoryId === combinedCategoryId
      ? combinedCategory
      : categories.find(({id}) => id === activeCategoryId) ?? {},
  );
  const prevActiveCategory = reactUtils.useDeepCompareMemo(
    categories.find(({id}) => id === prevActiveCategoryIdRef.current),
  );
  const activeContainerResource = useMemo(
    () => Object.values(activeCategory.resources ?? {}).find(({type, hidden}) => type === 'container' && !hidden) ?? {},
    [activeCategory],
  );
  const activeFormId = useMemo(() => {
    const {containerProps} = activeContainerResource ?? {};
    const formProps = typeof containerProps === 'function' ? containerProps({})?.formProps : containerProps?.formProps;

    return formProps?.id ?? '';
  }, [activeContainerResource]);

  hasFocusLockRef.current = activeContainerResource?.enableFocusLock;

  const handleFooterCheckboxChange = useCallback(() => {
    onFooterCheckboxChange?.(!footerCheckbox);
    setFooterCheckbox(footerCheckbox => !footerCheckbox);
  }, [footerCheckbox, onFooterCheckboxChange]);

  const showAll = !skipShowAll && activeCategoryId === combinedCategoryId && !query;

  const handleShowAllClick = useCallback(() => setSkipShowAll(true), []);

  const saveDropdownTippyInstance = useCallback(instance => {
    setDropdownTippyInstance(instance?.popper);
  }, []);

  const setCategories = useCallback(
    modifier => {
      // {[categoryId]: {hidden: boolean, resources: {[resourceId]: {hidden: true}}}}
      setCategoriesState(
        produce(categories, draft => {
          let resources;

          // for scenario when form is dirty and user navigates to a different category
          if (prefetcher.formId === activeFormId && prefetcher.formIsDirty) {
            // use categories previous state revert any hidden fn back from a boolean to fn
            resources = _.mapValues(
              _.find(prevStateCategoriesRef.current, ({id}) => id === activeCategoryId).resources,
              resource => ({
                hidden: typeof resource.hidden === 'function' ? resource.hidden : !resource.onCreateEnter,
              }),
            );

            // unset form from dirty
            PubSub.publish('FORM.DIRTY', {dirty: false}, {immediate: true});
          }

          if (categories !== prevStateCategoriesRef.current) {
            prevStateCategoriesRef.current = categories;
          }

          let previousActiveCategoryId;
          const modifierHasActiveCategory = Object.values(modifier).some(({active}) => active);

          if (modifierHasActiveCategory) {
            previousActiveCategoryId = activeCategoryId;
          }

          Object.entries({
            ...(previousActiveCategoryId && previousActiveCategoryId !== combinedCategoryId
              ? {[previousActiveCategoryId]: {active: false, resources}}
              : {}),
            ...modifier,
          }).forEach(([categoryId, categoryProps]) => {
            const categoryIndex = draft.findIndex(({id}) => id === categoryId);

            draft[categoryIndex] = _.merge(draft[categoryIndex], categoryProps);
          });
        }),
      );
    },
    [categories, activeCategoryId, prefetcher, activeFormId],
  );

  const setErrors = useCallback(
    modifier => {
      // {R1: {o1, o2}, ..., Rn: {o1}}
      setErrorsState(
        produce(errors, draft => {
          Object.assign(draft, modifier);

          Object.entries(draft).forEach(([resourceId, errorsInResource]) => {
            if (_.isEmpty(errorsInResource) || Object.values(errorsInResource).filter(Boolean).length === 0) {
              draft = _.omit(draft, resourceId);
            }
          });
        }),
      );
    },
    [errors],
  );

  const resetHighlightedAndSuggestion = useCallback(() => {
    // reset previous highlighted if exists
    if (highlightedPathArrRef.current?.length) {
      resetHighlightedChild(highlightedPathArrRef.current);
      setSuggestion('');

      //clear previous highlighted path and rect
      highlightedPathArrRef.current = null;
      highlightedRectRef.current = null;
      highlightedElementRef.current = null;
    }
  }, [resetHighlightedChild]);

  const setHighlighted = useCallback(
    (options = {}) => {
      // set new highlighted
      // setHighlightedChild returns undefined when highlighted is removed, reset highlighted path with empty array in that case
      const [pathArr, rect] = setHighlightedChild(options) ?? [];

      // [highlightedPathArrRef.current, highlightedRectRef.current] = result;

      if (rect) {
        [highlightedPathArrRef.current, highlightedRectRef.current] = [pathArr, rect];

        return;
      }

      const newOptions = {...options};

      switch (options.direction) {
        // If No element found in the left/right direction then search again in up/down direction
        case KEY_LEFT:
          newOptions.direction = KEY_UP;
          break;
        case KEY_RIGHT:
          newOptions.direction = KEY_DOWN;
          break;
        //If No element found in the up/down direction then search again with input as ref
        case KEY_UP:
        case KEY_DOWN:
          if (highlightedPathArrRef.current?.includes(VALUEPANEL_ID)) {
            const valuePanelElement = childrenPropsMap.get(VALUEPANEL_ID).element;

            valuePanelElement.scrollTop = valuePanelElement.scrollHeight;
          }

          newOptions.rect = childrenPropsMap.get(INPUT_ID)?.element.getBoundingClientRect();
          newOptions.pathArr = [];
          break;
      }

      [highlightedPathArrRef.current, highlightedRectRef.current] = setHighlightedChild(newOptions) ?? [];
    },
    [setHighlightedChild, childrenPropsMap],
  );

  const notifySelectionChange = useCallback(
    values => {
      onSelectionChange?.(new Map(values));
      setQuery('');

      if (!active) {
        resetHighlightedAndSuggestion();
      }

      if (active && closeDropdownOnSelection) {
        // Reset any query string and close the dropdown
        setQuery('');
        setActive(false);
      }

      resetHighlightedAndSuggestion();
      onBlur?.();
    },
    [active, closeDropdownOnSelection, onBlur, onSelectionChange, resetHighlightedAndSuggestion],
  );

  const handleSetHighlighted = useCallback(
    // Called on hover, reset existing highlighted state and set new highlighted by passing pathArr and optionId
    (evt, options = {}) => {
      resetHighlightedAndSuggestion();
      setHighlighted(options);
    },
    [setHighlighted, resetHighlightedAndSuggestion],
  );
  const handleReturnFocus = useCallback(
    ({force = false} = {}) => {
      const inputElement = childrenPropsMap.get(INPUT_ID)?.element;

      if ((force || !hasFocusLockRef.current) && document.activeElement !== inputElement) {
        setTimeout(() => childrenPropsMap.get(INPUT_ID)?.element?.focus(), 0);
      }
    },
    [childrenPropsMap],
  );

  const handleToggle = useCallback(
    evt => {
      evt.stopPropagation();

      const inputElement = childrenPropsMap.get(INPUT_ID)?.element;

      if (evt.target === inputElement) {
        setActive(true);

        return;
      }

      // Reset any query string
      setQuery('');
      setActive(active => !active);

      handleReturnFocus();
    },
    [handleReturnFocus, childrenPropsMap],
  );

  const showDiscardUnsavedChanges = useCallback(async () => {
    const {unsavedWarningData} = activeContainerResource ?? {};

    return new Promise(resolve =>
      PubSub.publish('UNSAVED.WARNING', {
        selfPublished: true,
        resolve,
        content: intl('Common.DiscardUnsavedChangesMessage'),
        ...unsavedWarningData,
        modalProps: {
          title: intl('Common.DiscardUnsavedChanges'),
          confirmProps: {text: intl('Common.Discard')},
          focusLockProps: {onDeactivation: handleReturnFocus},
          ...unsavedWarningData?.modalProps,
        },
      }),
    );
  }, [activeContainerResource, handleReturnFocus]);

  const handleGoBack = useCallback(
    async evt => {
      if (prefetcher.formId === activeFormId && prefetcher.formIsDirty) {
        // Cancel confirmation is mounted if active container resource(s) is a form and has unsaved changes
        const answer = await showDiscardUnsavedChanges();

        if (answer === 'cancel') {
          return answer;
        }

        // Reset formIsDirty in prefetcher to prevent displaying unsaved warning again
        PubSub.publish('FORM.DIRTY', {dirty: false}, {immediate: true});
      }

      setCategoriesState(prevStateCategoriesRef.current);
      handleReturnFocus(evt);
    },
    [prefetcher.formId, prefetcher.formIsDirty, activeFormId, handleReturnFocus, showDiscardUnsavedChanges],
  );

  const handleInputChange = useCallback(
    async (evt, query) => {
      if (prefetcher.formId === activeFormId && prefetcher.formIsDirty) {
        const answer = await handleGoBack(evt);

        if (answer === 'cancel') {
          return;
        }
      }

      inputProps?.onChange?.(evt, query);

      setActive(true);
      setSuggestion('');
      setQuery(query);
    },
    [activeFormId, prefetcher, handleGoBack, inputProps],
  );

  const handleReset = useCallback(() => {
    const newValues = prevPropValuesRef.current ?? new Map();

    setValuesState(newValues);
    setCategoriesState(prevPropCategoriesRef.current);
    setErrorsState(props.errors);
    setQuery('');
    notifySelectionChange(newValues);
  }, [notifySelectionChange, props.errors]);

  const handleCloseDropdown = useCallback(async () => {
    if (dropdownCloseIsHandledByParentRef.current) {
      // Calling handleCloseDropdown in a timeout allows Selector to listen to UNSAVED.WARNING if published by parent
      // and dropdownCloseIsHandledByParentRef.current is set to true if UNSAVED.WARNING is published by parent
      // reset it to false and skip handler to let parent handle discard changes confirmation
      dropdownCloseIsHandledByParentRef.current = false;

      return;
    }

    if (prefetcher.formId === activeFormId && prefetcher.formIsDirty) {
      // Mount a discard changes confirmation if dropdown form is dirty
      // Go back to previous state of dropdown - for e.g. unmount the container Form before closing the dropdown
      const answer = await handleGoBack();

      if (answer === 'cancel') {
        return;
      }
    }

    // Reset any query string and close the dropdown
    setQuery('');
    setActive(false);
    resetHighlightedAndSuggestion();
    onBlur?.();
  }, [activeFormId, prefetcher, handleGoBack, onBlur, resetHighlightedAndSuggestion]);

  const footerProps = {
    ...props.footerProps,
    footerCheckbox,
    onFooterCheckboxChange: handleFooterCheckboxChange,
    close: handleCloseDropdown,
  };

  const handleUnselectValues = useCallback(
    (evt, valuesToUnselectMap) => {
      domUtils.preventEvent(evt);

      resetHighlightedAndSuggestion();

      const newValues = produce(values, draft => {
        Array.from(valuesToUnselectMap).forEach(([resourceId, valuesToUnselect]) => {
          const unselectableValues = valuesToUnselect.filter(({sticky}) => !sticky);

          const valuesFromHandler = allResources[resourceId].onUnselect?.({
            valuesToUnselect: unselectableValues,
            footerCheckbox,
            allResources,
            values: draft,
            errors,
            categories,
            setQuery,
            setCategories,
            setErrors,
            close: handleCloseDropdown,
          });

          if (valuesFromHandler) {
            return valuesFromHandler;
          }

          const idPath = allResources[resourceId].optionProps?.idPath;
          const valuesInResource = draft
            .get(resourceId)
            .filter(
              selectedValue =>
                !unselectableValues.some(value => getOptionId(value, idPath) === getOptionId(selectedValue, idPath)),
            );

          if (valuesInResource.length) {
            draft.set(resourceId, valuesInResource);
          } else {
            draft.delete(resourceId);
          }
        });
      });

      if (newValues !== values) {
        // if the selector is fully controlled by values prop, we don't use the internal state at all,
        // so skip setting the state
        if (!alwaysTakeValuesFromProps) {
          setValuesState(newValues);
        }

        notifySelectionChange(newValues);
      }
    },
    [
      footerCheckbox,
      alwaysTakeValuesFromProps,
      allResources,
      errors,
      categories,
      values,
      setCategories,
      setErrors,
      handleCloseDropdown,
      resetHighlightedAndSuggestion,
      notifySelectionChange,
    ],
  );

  const handleSelectValue = useCallback(
    (evt, options) => {
      resetHighlightedAndSuggestion();

      let value = options.value;
      const resource = allResources[options.resourceId];

      if (value.isCreate) {
        // unhide the create form container resource and hide other resources in active category option panel and exit
        const categoryModifierObject = {
          [activeCategory.id]: {
            template: '',
            resources: _.mapValues(activeCategory.resources, (resourceVal, id) => ({
              hidden:
                id !==
                (typeof resource.onCreateEnter === 'function'
                  ? resource.onCreateEnter(value, resource)
                  : resource.onCreateEnter),
            })),
          },
        };

        if (active && closeDropdownOnSelection) {
          // Reset any query string and close the dropdown
          setQuery('');
          setActive(false);
        }

        setCategories(categoryModifierObject);

        return;
      }

      const selectIntoResource = resolveSelectIntoResource({allResources, resource, option: value, footerCheckbox});

      value = sanitizeOption(value); //Sanitize to omit multi mode flags

      const newValues = produce(values, draft => {
        // If onSelect hook is passed by page then first call it to receive new values that page wants to set
        const valuesFromHandler = selectIntoResource.onSelect?.(evt, {
          resource: selectIntoResource,
          footerCheckbox,
          allResources,
          value,
          values: draft,
          errors,
          categories,
          setQuery,
          setCategories,
          setErrors,
          setHistory: setHistoryState,
          goBack: handleGoBack,
          close: handleCloseDropdown,
        });

        if (valuesFromHandler) {
          // Set new values if it is provided by page
          return valuesFromHandler;
        }

        const conflicts = calculateConflicts(allResources, draft, {
          value,
          resource: selectIntoResource,
        });

        // if one of the conflict resolution is block, we can stop the option
        // from being selected and exit early.
        //
        // This has to be done before mutating the selected values
        if (conflicts.some(({resolution}) => resolution === 'block')) {
          return;
        }

        // when the code runs to here, all conflict resolution are "replace"
        for (const {
          resolution,
          selected: {values, value, resource},
        } of conflicts) {
          // this condition is always true
          if (resolution === 'replace') {
            // remove the conflicting selected value, and we add the option to selected values below
            _.pull(values, value);

            // if the values are empty now, remove if from the value map
            if (values.length === 0) {
              draft.delete(resource.id);
            }
          }
        }

        const valuesInResource = draft.get(selectIntoResource.id) ?? [];

        valuesInResource.push(value);

        if (draft.has(selectIntoResource.id)) {
          draft.delete(selectIntoResource.id);
        }

        draft.set(selectIntoResource.id, valuesInResource);
      });

      if (newValues !== values) {
        // if the selector is fully controlled by values prop, we don't use the internal state at all,
        // so skip setting the state.
        // If selected value is an option to select/unselect then add this option to selected values
        if (!alwaysTakeValuesFromProps) {
          setValuesState(newValues);
        }

        notifySelectionChange(newValues);
      }

      // Update history if applicable
      const {dataProvider, enableHistory, historyKey, optionProps: {idPath} = {}} = selectIntoResource;

      // Update kvPairs history
      const key = historyKey ?? (typeof dataProvider === 'string' ? dataProvider : selectIntoResource.id);
      const shouldUpdateKvPairs =
        enableHistory &&
        !value.isCreate &&
        !value.isPartial &&
        !history[key]?.some(val => getOptionId(val, idPath) === getOptionId(value, idPath));

      if (shouldUpdateKvPairs) {
        if (typeof value === 'object' && value.confirmed) {
          value = _.omit(value, 'confirmed');
        }

        const val =
          key === selectIntoResource.id
            ? value
            : typeof value === 'string'
            ? {value, resourceId: selectIntoResource.id}
            : {...value, resourceId: selectIntoResource.id};

        if (val) {
          setHistoryState(draft => {
            return {
              ...draft,
              [key]: [
                val,
                ...(draft[key]?.filter(value => getOptionId(value, idPath) !== getOptionId(val, idPath)) ?? []),
              ]
                .filter(Boolean)
                .splice(0, 25),
            };
          });
        }
      }
    },
    [
      footerCheckbox,
      alwaysTakeValuesFromProps,
      allResources,
      history,
      activeCategory,
      errors,
      values,
      categories,
      setCategories,
      setErrors,
      handleGoBack,
      handleCloseDropdown,
      resetHighlightedAndSuggestion,
      active,
      closeDropdownOnSelection,
      notifySelectionChange,
    ],
  );

  const handleClearValues = useCallback(evt => handleUnselectValues(evt, values), [values, handleUnselectValues]);

  const handleKeyDown = useCallback(
    async evt => {
      if (evt.keyCode === KEY_UP || evt.keyCode === KEY_DOWN) {
        setActive(true); // no-op if dropdown is already active
      }

      const input = childrenPropsMap.get(INPUT_ID)?.element;
      const movingHighlighted = useCmdKey
        ? isMovingHighlighted(evt)
        : evt.keyCode === KEY_UP ||
          evt.keyCode === KEY_DOWN ||
          (evt.keyCode === KEY_RIGHT && !suggestion && highlightedPathArrRef.current) ||
          (evt.keyCode === KEY_LEFT && (input.selectionStart === 0 || highlightedPathArrRef.current)); // cursor is at the begining of input

      if (
        prefetcher.formId === activeFormId &&
        prefetcher.formIsDirty &&
        ((useCmdKey && evt.keyCode === KEY_RIGHT && suggestion) ||
          (highlightedPathArrRef.current?.length && !movingHighlighted))
      ) {
        const answer = await handleGoBack(evt);

        if (answer === 'cancel') {
          resetHighlightedAndSuggestion();

          return;
        }
      }

      if (
        !hasFocusLockRef.current &&
        ((evt.keyCode === KEY_ESCAPE && active) ||
          // When highlight is in dropdown then tab event is handled by dropdown elements, skip closing dropdown in this case
          (evt.keyCode === KEY_TAB && !highlightedPathArrRef.current?.includes(DROPDOWN_ID)))
      ) {
        if (evt.keyCode === KEY_ESCAPE) {
          // escape should close the dropdown and stop propagating it to parent
          domUtils.preventEvent(evt);
        }

        // Close dropdown and clear input on escape
        // Close dropdown and clear input on tab if there is no highlighted child, otherwise tab event will be handled by highlighted child
        setQuery('');
        // To close the dropdown, Set active to false
        // it is possible to receive Tab/Shift+Tab events when dropdown is not active, skip setting active to prevent re-render
        setActive(active => (active === true ? false : active));

        return;
      }

      if (movingHighlighted) {
        // move highlighted to either valuePanel, categoryPanel or optionPanel
        domUtils.preventEvent(evt);

        const baseRect = highlightedRectRef.current;

        setHighlighted({rect: baseRect, pathArr: highlightedPathArrRef.current, direction: evt.keyCode});

        return;
      }

      if ((evt.keyCode === KEY_TAB || evt.keyCode === KEY_RIGHT) && suggestion && !query.includes(suggestion)) {
        // If suggestion exists and is not same as query then Right/Tab will autocomplete the suggestion
        domUtils.preventEvent(evt);

        setQuery(`${query}${suggestion}`);

        return;
      }

      if (
        highlightedPathArrRef.current?.length &&
        isHighlightedBlockEvent(evt, highlightedPathArrRef.current?.includes(VALUEPANEL_ID))
      ) {
        // highlightedPathArrRef includes an array of ids from parent to highlighted block
        // For E.g. ['dropdown', 'category_panel'], ['dropdown', 'category_panel', 'option_panel', 'R1'], ['dropdown', 'option_panel', 'R5']
        // Pass keydown event to highlighted block

        domUtils.preventEvent(evt);

        childrenPropsMap
          .get(highlightedPathArrRef.current[0])
          ?.keyDown?.(evt, {pathArr: highlightedPathArrRef.current.slice(1)});

        return;
      }

      if (values.size && evt.keyCode === KEY_BACK_SPACE && !query) {
        // find the nearest removable option.
        // skip sticky options
        const toRemove = new Map();

        for (const [resourceId, selectedValues] of Array.from(values).reverse()) {
          const lastNonStickyValue = selectedValues.findLast(value => !value?.sticky);

          if (lastNonStickyValue) {
            toRemove.set(resourceId, [lastNonStickyValue]);
            break;
          }
        }

        handleUnselectValues(evt, toRemove);
      }
    },
    [
      activeFormId,
      prefetcher,
      childrenPropsMap,
      query,
      values,
      suggestion,
      handleUnselectValues,
      handleGoBack,
      setHighlighted,
      resetHighlightedAndSuggestion,
      useCmdKey,
      active,
    ],
  );

  const handleClickOutside = useCallback(
    (_, evt) => {
      const searchBarElement = childrenPropsMap.get(SEARCHBAR_CONTAINER_ID)?.element;
      const dropdownElement = childrenPropsMap.get(DROPDOWN_ID)?.element;

      /* Effects and callbacks execution on click outside:
         handleClickOutside -> setCloseDropdown -> two effects: 1. handleCloseDropdown in a timeout 2. listen to UNSAVED.WARNING subscription
       */
      if (shouldCloseDropdown(evt.target, searchBarElement, modalContext, dropdownElement)) {
        /* when dropdown is not active then prefetcher fromIsDirty contains parent state,
           so when dropdown mounts we capture this information in a parentFormDirtyRef and reset prefetcher formIsDirty
           so that prefetcher formIsDirty reflects selector form state
        */
        if (!prefetcher.formIsDirty && parentFormDirtyRef.current) {
          /* when selector form is not dirty but parent form data exists then
             there are two scenarios w.r.t the target element of onClickOutside event
              1. target element is not a link, then we should close the dropdown without any unsaved warning confirmation
              2. target element is a link then we should flip prefetcher flags to parent form dirty data
                 so that prefetcher can handle unsaved changes warning
            Since we cannot determine whether target element is a link, we will flip prefetcher settings to parent data
           */
          PubSub.publish('FORM.DIRTY', parentFormDirtyRef.current, {immediate: true});
        }

        setCloseDropdown(true);
      }
    },
    [childrenPropsMap, modalContext, prefetcher],
  );

  const handleUpdateKVPairs = useCallback(
    category => {
      const historyEnabledResources = Object.keys(category.resources ?? {}).reduce((result, resourceId) => {
        const resource = allResources[resourceId];

        if (resource.hidden() || !resource.enableHistory) {
          return result;
        }

        result.push(resource);

        return result;
      }, []);

      if (historyEnabledResources.length === 0) {
        return;
      }

      // Move recent selections at the top of the list
      setHistoryState(history => {
        let nextHistory = {...history};

        historyEnabledResources.forEach(({id, historyKey, dataProvider, optionProps} = {}) => {
          const key = historyKey ?? (typeof dataProvider === 'string' ? dataProvider : id);

          const idPath = optionProps?.idPath;
          const selectedValues = [...values.values()].flat().reverse();

          if (nextHistory[key] && selectedValues.length) {
            // If values are selected and history exists then we need to reshuffle the options in the reverse order of selection
            // i.e. last selected should appear first in history
            nextHistory = {
              ...nextHistory,
              [key]: [
                ...selectedValues.map(value =>
                  nextHistory[key].find(val => getOptionId(val, idPath) === getOptionId(value, idPath)),
                ),
                ...nextHistory[key].filter(
                  value => !selectedValues.some(val => getOptionId(val, idPath) === getOptionId(value, idPath)),
                ),
              ].filter(Boolean),
            };
          }
        });

        shouldDispatchUpdateKVPairsRef.current = true;

        return nextHistory;
      });
    },
    [values, allResources],
  );

  useLayoutEffect(() => {
    if (categoriesPropHasChanged) {
      prevPropCategoriesRef.current = categories;
      // reset state if selector has reinitialized
      setCategoriesState(categories);
    }
  }, [categoriesPropHasChanged, categories]);

  useLayoutEffect(() => {
    if (errorsPropHasChanged) {
      prevPropErrorsRef.current = errors;
      // reset state if selector has reinitialized
      setErrorsState(errors);
    }
  }, [errorsPropHasChanged, errors]);

  useLayoutEffect(() => {
    if (valuesPropHasChanged) {
      prevPropValuesRef.current = valuesProp;

      // reset state if selector has reinitialized
      // if we are taking values from props then we don't use state at all
      if (!alwaysTakeValuesFromProps && shouldTakeValuesFromProp) {
        setValuesState(values);
      }
    }
  }, [alwaysTakeValuesFromProps, valuesPropHasChanged, shouldTakeValuesFromProp, values, valuesProp]);

  useLayoutEffect(() => {
    if (activeCategoryIdChangedByProp) {
      prevPropActiveCategoryId.current = nextPropActiveCategoryId;
    }

    if (!activeCategoryId) {
      return;
    }

    const prevActiveCategoryId = categories.find(({active}) => active)?.id;

    if (prevActiveCategoryId === activeCategoryId) {
      return;
    }

    setCategories({[activeCategoryId]: {active: true}});
  }, [nextPropActiveCategoryId, activeCategoryId, activeCategoryIdChangedByProp, setCategories, categories]);

  useEffect(() => {
    if (recentsPropChanged) {
      prevPropRecentsRef.current = history;
      setHistoryState(history);
    }
  }, [recentsPropChanged, history]);

  useEffect(() => {
    if (!historyIsEnabled) {
      return;
    }

    fetcher.spawn(fetchValidRecents);
  }, [fetcher, historyIsEnabled]);

  useEffect(() => {
    if (active) {
      onOpen?.();

      const token = PubSub.subscribe(
        'UNSAVED.WARNING',
        ({selfPublished}) => {
          dropdownCloseIsHandledByParentRef.current = !selfPublished;
        },
        {
          once: true,
        },
      );

      return () => {
        PubSub.unsubscribe(token);
        dropdownCloseIsHandledByParentRef.current = false;
      };
    }

    if (active === false) {
      onClose?.();
    }
  }, [active, onClose, onOpen]);

  useEffect(() => {
    if (closeDropdown) {
      const closeDropdownTimeout = setTimeout(() => {
        setCloseDropdown(false); // reset closeDropdown to false
        handleCloseDropdown(); // handleCloseDropdown is called in a timeout in useEffect, this effect is a result of setting closeDropdown state to true
      });

      return () => clearTimeout(closeDropdownTimeout);
    }
  }, [closeDropdown, handleCloseDropdown]);

  useEffect(() => {
    if (!active) {
      return;
    }

    if (active) {
      if (activeFormId) {
        const {formIsDirty, formId, resetForm} = prefetcher;

        if (active === true && (formIsDirty || formId || resetForm)) {
          // Capture parent form dirty data
          parentFormDirtyRef.current = {formId, dirty: formIsDirty, resetForm};
        }

        if (active || parentFormDirtyRef.current) {
          // Reset prefetcher form dirty flag when dropdown mounts and flip to parent data on unmount if parent form was dirty
          PubSub.publish('FORM.DIRTY', active ? {dirty: false} : parentFormDirtyRef.current, {immediate: true});
        }
      }
    }
  }, [prefetcher, active, activeFormId]);

  useEffect(() => {
    if (shouldDispatchUpdateKVPairsRef.current) {
      shouldDispatchUpdateKVPairsRef.current = false;

      // KVPairs is updated when either user selects a different category Or Dropdown is closed
      const selectedCategoryIsChanged = prevActiveCategory && prevActiveCategory.id !== activeCategory.id;

      if (selectedCategoryIsChanged || active === false) {
        // Update resource history, when either 1) dropdown closes 2) category is changed
        fetcher.spawn(updateSelectorHistory, {data: {recents: history}});
      }
    }
  }, [active, fetcher, activeCategory, prevActiveCategory, history]);

  useEffect(() => {
    // KVPairs is updated when either user selects a different category Or Dropdown is closed
    const selectedCategoryIsChanged = prevActiveCategory && prevActiveCategory.id !== activeCategory.id;
    const dropdownIsClosed = active === false;

    if (selectedCategoryIsChanged || dropdownIsClosed) {
      const category = dropdownIsClosed ? activeCategory : prevActiveCategory;

      handleUpdateKVPairs(category);
    }
  }, [active, handleUpdateKVPairs, activeCategory, prevActiveCategory]);

  // promises to retrieve suggestion and highlight information from Resources
  const searchPromises = useMemo(
    () => (query ? populateSearchPromises(renderedCategories, activeCategoryId) : {}),
    [query, renderedCategories, activeCategoryId],
  );

  useEffect(() => {
    if (_.isEmpty(searchPromises)) {
      resetHighlightedAndSuggestion();
    } else {
      const controller = new AbortController();

      controller.signal.onabort = () => {
        // cancel all pending promises
        for (const {onSearchReject} of Object.values(searchPromises)) {
          onSearchReject();
        }
      };

      (async () => {
        try {
          const suggestions = (await Promise.allSettled(Object.values(searchPromises).map(({promise}) => promise)))
            .filter(({status}) => status === 'fulfilled')
            .map(({value}) => value);

          if (!controller.signal.aborted) {
            const {suggestion, highlighted} = pickSuggestion(suggestions, query) ?? {};

            // reset previous highlighted if any and set new highlighted
            resetHighlightedAndSuggestion();

            setSuggestion(suggestion ?? '');
            setHighlighted(highlighted);
          }
        } catch (error) {
          if (__DEV__ && error) {
            console.log(error);
          }
        }
      })();

      return () => controller.abort();
    }
  }, [query, searchPromises, setHighlighted, resetHighlightedAndSuggestion]);

  const handleCategoryClick = useCallback(
    async (evt, categoryId) => {
      const categoryName = categories.find(({id}) => id === categoryId)?.name ?? '';
      const parts = categorySuggestionRegex.test(query) ? query.split(categorySuggestionRegex) : [];
      const categoryHintText = parts[2]?.trimStart();

      // Remove category hint text and keyword from query
      if (categoryHintText && categoryName.toLowerCase().includes(categoryHintText.toLowerCase())) {
        setQuery(query.split(categorySuggestionRegex)[0].trimEnd());
      }

      if (activeCategoryId === categoryId) {
        return;
      }

      // capture previous active category to update its kvpairs entries when active category changes
      // kvpairs recents entries are updated when we move away from an active category either by:
      // 1) selecting other category or 2) by closing the dropdown
      prevActiveCategoryIdRef.current = activeCategoryId;

      // before navigating to the selected category we must handle form when it is dirty
      if (prefetcher.formId === activeFormId && prefetcher.formIsDirty) {
        // Cancel confirmation is mounted if active container resource(s) is a form and has unsaved changes
        const answer = await showDiscardUnsavedChanges();

        if (answer === 'cancel') {
          return answer;
        }

        setQuery('');
      }

      setCategories({[categoryId]: {active: true}});
    },
    [categories, query, activeCategoryId, setCategories, activeFormId, prefetcher, showDiscardUnsavedChanges],
  );

  const handleSelectedValueClick = useCallback(
    (evt, categoryId) => {
      setActive(true);
      handleCategoryClick(evt, categoryId);
    },
    [handleCategoryClick],
  );

  const zIndex = typeof modalContext.zIndex === 'number' ? modalContext.zIndex + 1 : 'var(--shadow-above-all-z)';

  const dropdownMaxHeight = props.dropdownMaxHeight ?? 400;
  let maxColumns;

  if (typeof activeCategory.maxColumns === 'number') {
    maxColumns = noCategoryPanel ? activeCategory.maxColumns : activeCategory.maxColumns + 1;
  } else {
    maxColumns =
      activeCategory.maxColumns ?? (_.isEmpty(activeContainerResource) ? (noCategoryPanel ? 2 : 3) : 'unset');
  }

  const popperOptions = useMemo(
    () => [
      maxSize,
      {
        name: 'applyMaxSize',
        enabled: true,
        phase: 'beforeWrite',
        requires: ['maxSize'],
        fn({state}) {
          const {
            maxSize: {height: maxSizeHeight, width: availableWidth}, // The `maxSize` modifier provides this data
            popperOffsets,
          } = state.modifiersData;

          const {x: inputLeft} = state.rects.reference;
          const verticalMargin = 20;
          const horizontalMargin = 20;

          const availableHeight = maxSizeHeight - verticalMargin;

          const defaultMinHeight = dropdownMinHeight ?? 400;

          let maxHeight = availableHeight;
          let minHeight = defaultMinHeight;
          const styleRect = {};
          const styleMargin = {};

          const {top: selectorTop = 0, bottom: selectorBottom = 0} = selectorRef.current?.getBoundingClientRect() ?? {};

          const searchBarTop = selectorTop - verticalMargin;

          if (isExpandable) {
            const borderWidth = 1;
            const arrowOffset = 8;
            const {y: referenceTop, height: referenceHeight} = state.rects.reference;

            const referenceBottom = referenceTop + referenceHeight;

            styleMargin.marginTop = state.placement.includes('top')
              ? `${selectorBottom - referenceTop + arrowOffset + 2.5 * borderWidth}px` // positive margin so that dropdown cover selector height
              : `${selectorTop - referenceBottom - arrowOffset + 1.5 * borderWidth}px`; // negative margin to cover reference element height
          } else if (state.placement.includes('top')) {
            // When dropdown placement is top, we need to set a margin to prevent dropdown from overlapping over active indicator
            const activeIndicatorOffset = 10;

            // We need to calculate offset between top of the search bar and top of reference (input) and subtract this from available viewport height
            styleMargin.marginTop = `${searchBarTop - maxHeight - activeIndicatorOffset}px`;
            maxHeight = searchBarTop - activeIndicatorOffset - verticalMargin;
          }

          if (dropdownMaxHeight) {
            let maxHeightDropdown = dropdownMaxHeight;

            if (isExpandable) {
              const searchBarHeight = childrenPropsMap
                .get(SEARCHBAR_CONTAINER_ID)
                ?.element?.getBoundingClientRect().height;

              maxHeightDropdown += searchBarHeight;
            }

            maxHeight = Math.min(maxHeight, maxHeightDropdown);
            minHeight = Math.min(minHeight, maxHeight);
          }

          if (searchBarTop < minHeight && maxHeight < minHeight) {
            minHeight = Math.min(searchBarTop, maxHeight);
          }

          if (typeof maxColumns === 'number') {
            let maxColWidth = maxColumns * COLUMN_WIDTH;

            if (isExpandable) {
              const selectorWidth = selectorRef.current?.getBoundingClientRect().width ?? 0;

              maxColWidth = Math.max(maxColWidth, selectorWidth, availableWidth * 0.3);

              styleRect.width = `${maxColWidth + 2}px`;
            } else {
              styleRect.maxWidth = `${maxColWidth - horizontalMargin}px`;
            }
          } else {
            styleRect.maxWidth = maxColumns;
          }

          if (activeCategory.minColumns) {
            styleRect.minWidth = `${
              Math.min(
                (noCategoryPanel ? activeCategory.minColumns : activeCategory.minColumns + 1) * COLUMN_WIDTH,
                availableWidth,
              ) - horizontalMargin
            }px`;
          }

          styleRect.minHeight = `${minHeight}px`;
          styleRect.maxHeight = `${Math.max(minHeight, maxHeight)}px`;

          if (isExpandable) {
            styleMargin.left = 'calc(-1 * (var(--search-bar-container-padding-h) + var(--selector-items-padding-h)))';
          } else if (!noCategoryPanel && inputLeft > COLUMN_WIDTH) {
            // If inputLeft is greater than categoryPanel width then categorypanel will position on the left of optionpanel
            // optionPanelleft is calculated by adding categoryPanel width to dropdown left position
            const optionPanelLeft = popperOffsets.x + COLUMN_WIDTH;

            if (optionPanelLeft > inputLeft) {
              // Apply a left margin so that option panel is closer to input cursor
              styleMargin.left = `${inputLeft - optionPanelLeft}px`;
            }
          }

          if (!_.isEmpty(styleRect)) {
            Object.assign(state.elements.popper.style, styleRect);
          }

          if (!_.isEmpty(styleMargin)) {
            Object.assign(state.styles.popper, styleMargin);
          }
        },
      },
    ],
    [activeCategory, childrenPropsMap, dropdownMaxHeight, dropdownMinHeight, maxColumns, noCategoryPanel, isExpandable],
  );

  _.merge(dropdownTippyProps, {
    sticky: true, // Pass sticky true as dropdown rect changes its options loading is complete
    plugins: [sticky], // Checks for reference and popper rect changes and ensures that popper sticks to the reference
    placement: 'auto',
    onCreate: saveDropdownTippyInstance,
    light: true,
    bottomStart: true,
    instant: true,
    visible: active,
    arrow: false,
    flipBehavior: ['bottomStart', 'topStart'],
    interactive: true,
    onClickOutside: handleClickOutside,
    zIndex,
    noSingleton: true,
    popperOptions,
  });

  const tids = [tid];

  if (disabled) {
    tids.push('disabled');
  }

  if (insensitive) {
    tids.push('insensitive');
  }

  const elementProps = {
    footerCheckbox,
    activeCategory,
    allResources,
    errors,
    focusLockGroupName: focusLockGroupNameRef.current,
    insensitive,
    onMouseLeave: resetHighlightedAndSuggestion,
    onSetHighlighted: handleSetHighlighted,
    query,
    registerHandlers: registerChildHandlers,
    saveRef: saveChildRef,
    theme,
    values,
    notExpandable,
  };

  // reset is redundant when values are controlled
  const resetEnabled = enableReset && !alwaysTakeValuesFromProps;

  if (__DEV__ && enableReset && alwaysTakeValuesFromProps) {
    console.warn('"enableReset" is not effective when "alwaysTakeValuesFromProps" is true');
  }

  // active category is needed because this can be a result of sideeffect and not user action
  // Added activeCategory to avoid having multiple categories in active status

  return (
    <div
      className={cx(theme.selectorContainer, {[theme.focused]: active})}
      data-tid={active && isExpandable ? 'comp-selector' : tidUtils.getTid('comp-selector', tids)}
      ref={selectorRef}
    >
      {label && (
        <label className={theme.label} onClick={handleToggle}>
          {label}
        </label>
      )}
      <div className={theme.selector}>
        <SearchBar
          {...elementProps}
          autoFocus={autoFocus}
          isExpander={isExpandable && active}
          inputProps={{
            'aria-controls': dropdownTippyInstance?.id,
            ...inputProps,
          }}
          hasFocusLockWithContainerResource={hasFocusLockRef.current}
          placeholder={placeholder}
          hideClearAll={hideClearAll || hideOptions}
          disabled={disabled}
          noActiveIndicator={noActiveIndicator || noCategoryPanel}
          title={title}
          active={active}
          suggestion={suggestion}
          error={typeof errorMessage === 'string'}
          searchBarMaxHeight={searchBarMaxHeight}
          onSelectedValueClick={handleSelectedValueClick}
          onValueRemove={handleUnselectValues}
          onKeyDown={handleKeyDown}
          onToggle={handleToggle}
          onInputChange={handleInputChange}
          onClearValues={handleClearValues}
          onSearchBarClick={handleToggle}
          onReturnFocus={handleReturnFocus}
          setCategoryPanelOnRight={isExpandable ? undefined : setCategoryPanelOnRight}
          hideOptions={hideOptions}
        />
        {(helpInfo || resetEnabled) && (
          <>
            {helpInfo && (
              <StatusIcon status="help" theme={theme} themePrefix="selectorAlignedIcon-" tooltip={helpInfo} />
            )}
            {resetEnabled && (
              <Button
                noFill
                disabled={disabled || isValuesMapEqual(valuesProp, valuesState, allResources)}
                insensitive={insensitive}
                tid="revert"
                icon="revert"
                tooltip={intl('Common.Reset')}
                onClick={handleReset}
              />
            )}
          </>
        )}
      </div>
      <TypedMessages key="status" gap="gapXSmall" theme={theme}>
        {[
          !hideErrorMessage && errorMessage?.trim()
            ? {content: errorMessage, color: 'error', fontSize: 'var(--12px)', tid: 'comp-selector-errormessage'}
            : null,
        ]}
      </TypedMessages>
      {active && (
        <Tooltip
          tid={tidUtils.getTid('comp-selector', tids)}
          theme={theme}
          themePrefix="dropdown-"
          content={
            <>
              {isExpandable && (
                <SearchBar
                  {...elementProps}
                  autoFocus={autoFocus}
                  inputProps={{
                    'aria-controls': dropdownTippyInstance?.id,
                    ...inputProps,
                  }}
                  hasFocusLockWithContainerResource={hasFocusLockRef.current}
                  placeholder={placeholder}
                  hideClearAll={hideClearAll || hideOptions}
                  disabled={disabled}
                  noActiveIndicator={noActiveIndicator || noCategoryPanel}
                  title={title}
                  active={active}
                  suggestion={suggestion}
                  error={typeof errorMessage === 'string'}
                  searchBarMaxHeight={searchBarMaxHeight}
                  onSelectedValueClick={handleSelectedValueClick}
                  onValueRemove={handleUnselectValues}
                  onKeyDown={handleKeyDown}
                  onToggle={handleToggle}
                  onInputChange={handleInputChange}
                  onClearValues={handleClearValues}
                  onSearchBarClick={handleToggle}
                  hideOptions={hideOptions}
                />
              )}
              <Dropdown
                {...elementProps}
                dropdownTippyInstance={dropdownTippyInstance}
                onReturnFocusToInput={handleReturnFocus}
                searchPromises={searchPromises}
                categories={renderedCategories}
                categoryPanelOnRight={categoryPanelOnRight}
                infoPanel={infoPanel}
                noCategoryPanel={noCategoryPanel}
                history={history}
                footer={footer}
                footerProps={footerProps}
                noFooter={noFooter}
                showAll={showAll}
                onShowAllClick={handleShowAllClick}
                onBack={handleGoBack}
                onCategoryClick={handleCategoryClick}
                onOptionSelect={handleSelectValue}
                onOptionUnselect={handleUnselectValues}
                setQuery={setQuery}
                setCategories={setCategories}
                onClose={handleCloseDropdown}
              />
            </>
          }
          reference={childrenPropsMap.get(isExpandable ? INPUT_ID_EXPANDABLE : INPUT_ID)?.element}
          maxWidth="96vw"
          {...dropdownTippyProps}
        />
      )}
    </div>
  );
}

Selector.categoryPresets = CategoryPresets;
Selector.emptyMessage = '';
Selector.undefinedValue = undefined;
Selector.MultiMode = MultiModeSelector;
Selector.GridFilter = GridFilter;
