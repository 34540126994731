/**
 * Copyright 2024 Illumio, Inc. All Rights Reserved.
 */
import {attributeIds, logicOperatorIds, operatorIds} from './LabelRulesConstants';

/**
 * converts a UI 'operator' type to the API 'operator' type
 * @param operator {string}
 * @returns string
 */
export const serializeOperator = operator =>
  ({
    [operatorIds.startsWith]: 'starts_with',
    [operatorIds.endsWith]: 'ends_with',
    [operatorIds.contains]: 'contains',
    [operatorIds.regex]: 'regex',
    [operatorIds.is]: 'equals',
    [operatorIds.isIn]: 'is_in',
  })[operator];

/**
 * converts a UI 'attribute' type to the API 'property' type
 * @param attribute {string}
 * @returns string
 */
export const serializeAttribute = attribute =>
  ({
    [attributeIds.os]: 'os',
    [attributeIds.hostname]: 'hostname',
    [attributeIds.ip]: 'ip_address',
    [attributeIds.process]: 'process',
    [attributeIds.port]: 'port',
  })[attribute];

/**
 * serializes the values of a condition into the API's format
 * @param attribute
 * @param operator
 * @param values
 * @returns {({port: *, proto: *, toPort: *}|*)[]}
 */
export const serializeConditionValues = ({attribute, operator, values = []}) => {
  return values.map(value => {
    if (attribute === attributeIds.port && (operator === operatorIds.is || operator === operatorIds.isIn)) {
      return {
        ...(value.hasOwnProperty('port') ? {port: value.port} : {}),
        ...(value.hasOwnProperty('toPort') ? {to_port: value.toPort} : {}),
        ...(value.hasOwnProperty('proto') ? {proto: value.proto} : {}),
      };
    }

    return value;
  });
};

/**
 * converts the UI 'condition' to the API 'condition'
 * @param condition
 * @returns {{property: string, values: string[], operator: string}}
 */
export const serializeCondition = condition => {
  const property = serializeAttribute(condition.attribute);
  const operator = serializeOperator(condition.operator);
  const values = serializeConditionValues({
    attribute: condition.attribute,
    operator: condition.operator,
    values: condition.values,
  });

  return {
    property,
    operator,
    values,
  };
};

/**
 * converts UI 'expression' format into API 'expression' format
 * @param expression
 * @returns {{property: string, values: string[], operator: string}|{logical_operator, child_expressions: expression[]}}
 */
export const serializeExpression = expression => {
  // expression is a condition; just serialize it as a condition;
  if (!expression.hasOwnProperty('childExpressions')) {
    return serializeCondition(expression);
  }

  // expression is complex; serialize it recursively;
  return {
    logical_operator: expression.logicOperator,
    child_expressions: expression.childExpressions.map(childExpr => serializeExpression(childExpr)),
  };
};

/**
 * converts UI 'labels' to API 'label_assignments'
 * @param labels
 * @returns {*[]}
 */
export const serializeLabels = labels => labels.map(({href}) => ({label: {href}}));

/**
 * converts a UI 'rule' to an API 'rule'
 * @param rule - rule object
 * @returns {{expression: ({property: string, value: string, operator: string}|{logical_operator, child_expressions: expression[]}), label_assignments: *[]}}
 */
export const serializeRule = rule => {
  const serializedRule = {
    expression: serializeExpression(rule.expression),
    label_assignments: serializeLabels(rule.labels),
  };

  if (rule.hasOwnProperty('enabled')) {
    serializedRule.enabled = rule.enabled;
  }

  return serializedRule;
};

/**
 * returns the payload for an 'update rule' request
 * @param rule
 * @returns {{expression: ({property: string, value: string, operator: string}|{logical_operator, child_expressions: expression[]}), label_assignments: *[]}}
 */
export const getUpdateLabelRulePayload = rule => serializeRule(rule);

/**
 * returns the payload for a 'create rule' request
 * @param rule - rule object
 * @returns {{expression: ({property: string, value: string, operator: string}|{logical_operator, child_expressions: expression[]}), label_assignments: *[]}}
 */
export const getCreateLabelRulePayload = rule => serializeRule(rule);

/**
 * returns the payload for a 'delete rules' request
 * @param rules {*[]}
 * @returns {{rules: *[]}}
 */
export const getDeleteLabelRulesPayload = rules => ({
  rules: rules.map(({href}) => ({href})),
});

/**
 * converts API 'operator' to a UI 'operator'
 * @param operator {string}
 * @returns string
 */
export const unSerializeOperator = operator =>
  ({
    starts_with: operatorIds.startsWith,
    ends_with: operatorIds.endsWith,
    contains: operatorIds.contains,
    regex: operatorIds.regex,
    equals: operatorIds.is,
    is_in: operatorIds.isIn,
  })[operator];

/**
 * converts API 'property' to UI 'attribute'
 * @param attribute {string}
 * @returns string
 */
export const unSerializeAttribute = attribute =>
  ({
    os: attributeIds.os,
    hostname: attributeIds.hostname,
    ip_address: attributeIds.ip,
    process: attributeIds.process,
    port: attributeIds.port,
  })[attribute];

/**
 * un-serializes the API's format for condition values;
 * @param attribute
 * @param operator
 * @param values
 * @returns {({port: *, proto: *, to_port: *}|*)[]}
 */
export const unSerializeConditionValues = ({attribute, operator, values = []}) => {
  return values.map(value => {
    if (attribute === attributeIds.port && (operator === operatorIds.is || operator === operatorIds.isIn)) {
      return {
        ...(value.hasOwnProperty('port') ? {port: value.port} : {}),
        ...(value.hasOwnProperty('to_port') ? {toPort: value.to_port} : {}),
        ...(value.hasOwnProperty('proto') ? {proto: value.proto} : {}),
      };
    }

    return value;
  });
};

/**
 * converts API 'condition' to UI 'condition'
 * @param condition
 * @returns {{values: string[], attribute: string, operator: string}}
 */
export const unSerializeCondition = (condition = {}) => {
  const attribute = unSerializeAttribute(condition.property);
  const operator = unSerializeOperator(condition.operator);
  const values = unSerializeConditionValues({attribute, operator, values: condition.values});

  return {
    attribute,
    operator,
    values,
  };
};

/**
 * converts API 'expression' format to UI 'expression' format
 * see Expression in LabelRuleTypes.ts for a type definition of the return value
 * @param expression
 * @returns {{childExpressions: expression[], logicOperator: string}|{attribute: string, value: string, operator: string}}
 */
export const unSerializeExpression = expression => {
  // expression is a condition; just serialize it as a condition;
  if (!expression.hasOwnProperty('child_expressions')) {
    return unSerializeCondition(expression);
  }

  // expression is complex; un-serialize it recursively
  return {
    logicOperator: expression.logical_operator,
    childExpressions: expression.child_expressions.map(childExpr => unSerializeExpression(childExpr)),
  };
};

/**
 * converts API 'label_assignments' to UI 'labels'
 * @param labels
 * @returns {*[]}
 */
export const unSerializeLabels = (labels = []) => labels.map(({label}) => label);

/**
 * converts API 'rule' to UI 'rule'
 * @param rule
 * @returns {{expression: ({childExpressions: expression[], logicOperator: string}|{attribute: string, value: string, operator: string}), updatedBy, href, position, enabled, labels: *[], updatedAt}}
 */
export const unSerializeRule = (rule = {}) => ({
  href: rule.href,
  position: rule.position,
  enabled: rule.enabled,
  labels: unSerializeLabels(rule.label_assignments),
  expression: unSerializeExpression(rule.expression),
  updatedAt: rule.updated_at,
  updatedBy: rule.updated_by,
});

/**
 * parses the list of rules returned from a 'get rules' request
 * @param rules
 * @returns {{expression: ({childExpressions: expression[], logicOperator: string}|{attribute: string, value: string, operator: string}), updatedBy, href, position, enabled, labels: *[], updatedAt}[]}
 */
export const parseLabelRules = (rules = []) => rules.map(rule => unSerializeRule(rule));

/**
 * returns true if the condition is valid; returns false if condition is invalid;
 * @param condition
 * @returns {boolean}
 */
export const validateCondition = condition => {
  // verify that the condition has an attribute, operator, and a values array with at least one item and no falsy items
  return (
    Boolean(condition?.attribute && condition?.operator && condition?.values?.length) &&
    condition.values.every(value => Boolean(value))
  );
};

/**
 * returns true if the expression is valid; false if expression is invalid;
 * @param expression
 */
export const validateExpression = expression => {
  // no empty conditions/expressions
  if (!expression) {
    return false;
  }

  // expression is just a condition; validate the condition;
  if (!expression.hasOwnProperty('childExpressions')) {
    return validateCondition(expression);
  }

  // validate the complex expression's logicOperator
  if (![logicOperatorIds.and, logicOperatorIds.or, logicOperatorIds.not].includes(expression.logicOperator)) {
    return false;
  }

  // validate that childExpressions is an array
  if (!Array.isArray(expression.childExpressions)) {
    return false;
  }

  // if it is a 'not' expression; validate that it has one child expression;
  if (expression.logicOperator === logicOperatorIds.not && expression.childExpressions.length !== 1) {
    return false;
  }

  // if it is an 'and' or 'or' expression; validate that it has two or more child expressions;
  if (
    (expression.logicOperator === logicOperatorIds.and || expression.logicOperator === logicOperatorIds.or) &&
    expression.childExpressions.length < 2
  ) {
    return false;
  }

  // recursively validate the child expressions
  return expression.childExpressions.every(childExpr => validateExpression(childExpr));
};

/**
 * verifies that labels array has at least one label, and that all labels are valid
 * @param labels
 * @returns {boolean}
 */
export const validateLabels = labels =>
  Boolean(labels?.length) && labels.every(label => Boolean(label?.href) || Boolean(label?.key && label?.value));

/**
 * verifies that rule number is positive integer and greater than zero.
 * @param ruleNumber {number} - the rule number
 * @param maxRuleNumber {number} - max value of the rule number
 * @returns {boolean}
 */
export const validateRuleNumber = (ruleNumber, maxRuleNumber) =>
  ruleNumber === undefined || (Number.isInteger(ruleNumber) && ruleNumber >= 1 && ruleNumber <= maxRuleNumber);

/**
 * verifies that a rule is valid
 * @param rule {object} - the rule object ({href, expression, labels, ruleNumber})
 * @param maxRuleNumber {number} - max value of the rule's ruleNumber
 * @returns {boolean}
 */
export const validateRule = (rule, {maxRuleNumber}) =>
  Boolean(rule) &&
  Boolean(rule.href) &&
  validateRuleNumber(rule.ruleNumber, maxRuleNumber) &&
  validateExpression(rule.expression) &&
  validateLabels(rule.labels);
